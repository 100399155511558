import { Grid, Typography, Box, Card, CardContent } from "@mui/material";

import pedalsImage from "../../../static/images/revf/rev_f_pedals.webp"

const Pedals = () => {
    return <Grid id="ampBanner" container sx={{ width: "100%", pt: 0, pb: 0, background: "radial-gradient(circle, #eee 0%, #eee 100%)" }} justifyContent={"center"} >
        <Box>
            <img loading="lazy" alt="Amp Suite Rev F Pedals" src={pedalsImage} style={{ maxWidth: "100%", display: "block", width: "auto", height: "auto" }} />
        </Box>

        <Grid item sx={{ p: 2, pt: 0, }} xs={12} md={6}>

            <Typography sx={{ width: "100%", mt: { xs: -5, md: -5 }, mb: 2 }} textAlign="left" variant="h3" color="black">Pedals</Typography>

            <Typography sx={{ width: "100%", mb: 2, fontSize: { xs: "14px", md: "20px" } }} textAlign="left" variant="body1" color="black"><b>X-Drive:</b> A classic drive with a bit more bite than you're used to. The sharper midrange makes it a perfect match for the Rev F!</Typography>
            <Typography sx={{ width: "100%", mb: { xs: 2, md: 8 }, fontSize: { xs: "14px", md: "20px" } }} textAlign="left" variant="body1" color="black"><b>Forte Boost:</b> 22 dB of pure boost. Perfect for when you need to tune just that little bit lower.</Typography>
        </Grid>
    </Grid>
}

export default Pedals;
