import { Grid, Typography } from "@mui/material";

import delayImage from "../../../static/images/revf/delay.webp"

const DelayBanner = () => {
  return <Grid container sx={{ width: "100%", pt: 0, pb: 0, background: "radial-gradient(circle, rgb(240, 240, 240) 0%, rgb(150, 150, 150) 100%)" }} justifyContent={"center"}>
    <Grid item xs={12} md={12} justifyContent={"center"} alignItems={"center"}>
      <Grid container sx={{ width: "100%", textAlign: "center" }} alignItems={"center"} >

        <Grid item xs={12} md={6} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, color: "#000", }}>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>Delay and Chorus</Typography>
          <Typography fontFamily={"montserrat"} sx={{ mt: 2, fontSize: { md: "30px", xs: "16px" } }} > A dedicated time based fx unit is included to create inspiring lead tones.</Typography>
        </Grid>
        <Grid item xs={12} md={6} alignItems={"center"}>
          <img loading="lazy" fetchpriority="low" alt="Amp Suite Rev F Delay" width="100%" src={delayImage} />
        </Grid>
      </Grid>
    </Grid>
  </Grid >
}

export default DelayBanner;
