import { Grid, Typography, Box } from "@mui/material"
import BottomBar from "../../components/BottomBar"
import TopBar from "../../components/TopBar"
import { Helmet } from "react-helmet"


const BaseContent = <p>
  Your privacy is important to us. We don’t ask for your personal information unless we truly need it.
  <br />It is WaveMind’s policy to respect your privacy regarding any information we may collect from you across our website, <a href="/">wavemind.nl</a>.
</p>

const LogDataContent = <p>
  We log general information about your browser whenever you visit our website. This information may include your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visit on our site, the time and date of your visit, and the time spent on each page.
  <br /><br />Like most website operators, we collect this data to better understand how our visitors use our services, and how we may improve your experience of our website in future. We do not share data specifics publicly but may disclose aggregated and anonymized versions of this information, e.g. in website usage trend reports.
</p>

const PersonalInformationContent = <p>
  We may ask you for personal information, such as your name, email, address, contact details and payment details. We collect only the personal information relevant to providing you with a service and use your information only to ensure the fulfilment of this service. You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services without this information.
  <br /><br />We will only retain personal information for as long as necessary to provide you with a service.
</p>

const ThirdPartyServicesContent = <p>
  We may employ third-party companies and individuals on our websites – for example, analytics providers and content partners. These third parties have access to your personal information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose.
</p>

const SecurityContent = <p>
  We take security seriously and do what we can within commercially acceptable means to protect your personal information from loss or theft, as well as unauthorized access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure and cannot guarantee the absolute security of your data.
</p>

const LinksToOtherSitesContent = <p>
  Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot assume responsibility for their treatment of your personal information. This privacy policy only covers our website and privacy practices.
</p>

const ChangesToOurPrivacyPolicyContent = <p>
  At our discretion, we may change our privacy policy from time to time. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information.
</p>

const BussinessTransfers = <p>
  If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include user information among our assets transferred to or acquired by a third party. You acknowledge that such transfers may occur, and that any parties who acquire us may continue to use your personal information according to this policy.
</p>

const CookiePolicyContent = <p>
  We use cookies to help us improve your experience of our website, <a href="/">wavemind.nl</a>. This cookie policy is part of our privacy policy and covers the use of cookies between your device and our site. We also provide basic information on third-party services we may use on our website, who may also use cookies as part of their service, though they are not covered by our policy.
  <br /><br />If you do not wish to accept cookies from us, you should instruct your browser to refuse cookies from our website, with the understanding that we may be unable to provide you with some of your desired content and services.
  <br /><br />At our discretion, we may change our privacy policy at any time. Any changes will be reflected here, so we encourage you to visit this page regularly. We will also notify our registered users of updates to our policy. Your continued use of this site after any changes to this policy will be regarded as acceptance of our cookie practices as described.
</p>

const WhatIsACookieContent = <p>
  A cookie is a small piece of data that a website stores on your device when you visit, typically containing information about the website itself, a unique identification number that allows the website to recognize your web browser when you return, additional data that serves the purpose of the cookie, and the lifespan of the cookie itself.
  <br />
  <br />
  Cookies are used to enable certain features (e.g. logging in), to track site usage (e.g. analytics), to store your user settings (e.g. time zone, notification preferences), and to personalize your content (e.g. advertising, language).
  <br />
  <br />
  Cookies set by the website you are visiting are normally referred to as “first - party cookies”, and typically only track your activity on that particular site.Cookies set by other sites and companies(i.e.third parties) are called “third - party cookies” and can be used to track you on other websites that use the same third - party service.
</p >

const EssentialCookiesContent = <p>
  Essential cookies are crucial to your experience of a website, enabling core features like user logins, account management, shopping carts and payment processing. We use essential cookies to enable certain functions on our website.
</p>

const PerformanceCookiesContent = <p>
  Performance cookies are used in the tracking of how you use a website during your visit, without collecting personal information about you. Typically, this information is anonymous and aggregated with information tracked across all site users, to help companies understand visitor usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their audience’s overall website experience. These cookies may be set by the website you’re visiting (first party) or by third-party services. We use performance cookies on our site.
</p>

const FunctionalityCookiesContent = <p>
  Functionality cookies are used in collecting information about your device and any settings you may configure on the website you’re visiting (like language and time zone settings). With this information, websites can provide you with customized, enhanced or optimized content and services. These cookies may be set by the website you’re visiting (first party) or by third-party service. We use functionality cookies for selected features on our site.
</p>

const TargetCookiesContent = <p>
  Targeting/advertising cookies are used in determining what promotional content is more relevant and appropriate to you and your interests. Websites may use them to deliver targeted advertising or to limit the number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns and the quality of content presented to you. These cookies may be set by the website you’re visiting (first party) or by third-party services. Targeting/advertising cookies set by third parties may be used to track you on other websites that use the same third-party service. We use targeting/advertising cookies on our site.
</p>

const ThirdPartyCookiesContent = <p>
  We may employ third-party companies and individuals on our websites — for example, analytics providers and content partners. We grant these third parties’ access to selected information to perform specific tasks on our behalf. They may also set third-party cookies in order to deliver the services they are providing. Third-party cookies can be used to track you on other websites that use the same third-party service. As we have no control over third-party cookies, they are not covered by WaveMind’s cookie policy.
</p>

const CookiePromiseContent = <p>
  We review the privacy policies of all our third-party providers before enlisting their services to ensure their practices align with ours. We will never knowingly include third-party services that compromise or violate the privacy of our users.
</p>

const OptOutContent = <p>
  If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our website. Most browsers are configured to accept cookies by default, but you can update these settings to either refuse cookies altogether, or to notify you when a website is trying to set or update a cookie. If you browse websites from multiple devices, you may need to update your settings on each individual device.
  <br /><br />Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies may mean you are unable to access certain features and content across the sites you visit.
</p>

const Paragraph = ({ title, content, smallTitle }) => {
  return <Box sx={{ mt: 2 }}>
    <Typography variant={smallTitle ? "body1" : "h6"}><b>{title}</b></Typography>
    <Typography sx={{ pl: 2 }} variant="body1">{content}</Typography>
  </Box>
}

const PrivacyPolicy = () => {
  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <Helmet>
      <title>WaveMind Audio | Privacy Policy</title>
      <link rel="canonical" href="https://wavemind.net/privacy" />
    </Helmet>

    <TopBar />
    <Grid sx={{ justifyContent: "center", flex: 500, padding: 4, mb: 4 }} container>
      <Grid item xs={12} md={8} lg={6}>
        <Typography variant="h2">Privacy Policy</Typography>
        <Paragraph content={BaseContent} />

        <Paragraph title="1. Log Data" content={LogDataContent} />
        <Paragraph title="2. Personal Information" content={PersonalInformationContent} />
        <Paragraph title="3. Third-Party Services" content={ThirdPartyServicesContent} />
        <Paragraph title="4. Security" content={SecurityContent} />
        <Paragraph title="5. Links to Other Sites" content={LinksToOtherSitesContent} />
        <Paragraph title="6. Changes to our Privacy Policy" content={ChangesToOurPrivacyPolicyContent} />
        <Paragraph title="7. Bussiness Transfers" content={BussinessTransfers} />
        <Paragraph title="8. Cookie Policy" content={CookiePolicyContent} />
        <br />
        <br />
        <Paragraph smallTitle title="What is a cookie?" content={WhatIsACookieContent} />
        <Paragraph smallTitle title="Essential Cookies" content={EssentialCookiesContent} />
        <Paragraph smallTitle title="Performance Cookies" content={PerformanceCookiesContent} />
        <Paragraph smallTitle title="Functionality Cookies" content={FunctionalityCookiesContent} />
        <Paragraph smallTitle title="Targeting/advertising Cookies" content={TargetCookiesContent} />
        <Paragraph smallTitle title="Third-party Cookies on our Site" content={ThirdPartyCookiesContent} />
        <Paragraph smallTitle title={<i>Our third-party privacy promise</i>} content={CookiePromiseContent} />
        <Paragraph smallTitle title="How you can control or opt out of cookies" content={OptOutContent} />
      </Grid>
    </Grid>
    <BottomBar />
  </div>
}

export default PrivacyPolicy;