import { Grid, Typography, Box } from "@mui/material";

import outputControls from "../../../static/images/cn_amp/Output_section.png"

const DynamicsBanner = () => {
  const textColor = "#000"
  return <Box textAlign="center" justifyContent="center" id="cabBanner" container sx={{ width: "100%", pt: { xs: 4, md: 10 }, pb: { xs: 4, md: 10 }, background: "radial-gradient(circle, #FF5631 0%, rgb(170, 27, 31) 100%)" }}>
    <Grid container alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }}>
      <Grid item xs={12} md={8}>
        <Grid container justifyContent={"center"}>
          <Grid item id="overview" xs={12} md={4} justifyContent={"center"} alignItems={"center"} sx={{ mb: { xs: 4, md: 0 }, pr: { xs: 0, md: 10 } }} >
            <img loading="lazy" src={outputControls} style={{ width: "100%", maxWidth: "300px", borderRadius: "25px" }} alt="output" />
          </Grid >

          <Grid item id="dynamics" xs={12} md={8} justifyContent={"center"} alignItems={"center"} sx={{ textAlign: { xs: "left", md: "center" }, flexDirection: "column", display: "flex", pl: { xs: 4, md: 10 }, pr: { xs: 4, md: 10 } }} >
            <Typography variant="h3" sx={{ textAlign: { xs: "left", md: "center" }, mb: { xs: 2, md: 5 } }}>
              Control Your Tone
            </Typography>

            <Typography sx={{ textAlign: { xs: "left", md: "center" }, fontSize: { md: "23px", xs: "16px" } }} variant="body2">
              Our proprietary dynamics section helps you tame the lowest of lows and the shrillest of highs. These simple one knob controls make it easy to dial in your sound to perfection.
            </Typography>
          </Grid >
        </Grid>
      </Grid>
    </Grid>
  </Box >
}

export default DynamicsBanner;
