import axios from "axios";


export const resendConfirmationEmail = async (userID: string) => {

    const data = {
        user_id: userID,
    }

    return await axios.post(
        `/api/email/resend`,
        data
    )
}

export const confirmEmail = async (key: string) => {

    const data = {
        key: key,
    }

    return await axios.post(
        `/api/email/confirm`,
        data
    )
}

export const subscribeEmail = async (email: string) => {
    const data = {
        email: email
    }

    return await axios.post("/api/email/subscribe", data)
}