import * as React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { CLEAR_NOTIFICATION } from '../types/actions';
import { Typography } from '@mui/material';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const setNotification = () => ({
  type: CLEAR_NOTIFICATION,
  payload: null
})

const NotificationComponent = () => {

  const state = useSelector((state) => state)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setNotification())
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
      open={state?.notification?.message !== undefined}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={state?.notification?.severity} sx={{ width: '100%' }}>
        <Typography><div dangerouslySetInnerHTML={{ __html: state?.notification?.message }}></div></Typography>
      </Alert>
    </Snackbar>
  )
}

export default NotificationComponent;