import { Grid, Typography, Box, Card, CardContent } from "@mui/material";

import ecocideBand from "../../../static/images/cn_amp/ecocide.jpg"

const TEXT_COLOR = "#fff";

const Paragraph = ({ title, children, smallTitle }) => {
  return <Box sx={{ mt: 2, textAlign: "left" }}>

    <Typography sx={{ fontSize: { md: "23px", xs: "16px" } }} variant="body2" ><b>{title}:</b> {children}</Typography>
  </Box>
}

const OverviewBanner = () => {
  return <Grid container sx={{
    width: "100%",
    pt: "80px",
    pb: { xs: 0, md: 20 },
    // minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 1.0) 0%, #1a1a1a 99%)",
  }}

    justifyContent={"center"} >

    <Grid item id="overview" xs={12} md={6} justifyContent={"center"} alignItems={"center"} sx={{ pl: { xs: 4, md: 10 }, pr: { xs: 4, md: 10 } }} >
      <Typography variant="h3" sx={{ mb: { xs: 2, md: 5 } }}>
        About the plugin
      </Typography>

      <Typography sx={{ fontSize: { md: "23px", xs: "16px" } }} variant="body2">
        <i>“When Chris from WaveMind approached me to do this project, there was only one condition. It had to be better than all of the modelers I was using at the time. I truly feel like we managed to do that, it instantly replaced all guitar tones in my projects. I'm really proud of how it turned out and excited to share it with everyone else!”</i>
      </Typography>
      <Typography sx={{ float: "right", mt: -1, ml: "auto", mr: 0, fontSize: { md: "23px", xs: "16px" } }} variant="body2">
        <i>- Daniël Metselaar </i>
      </Typography>

      <Typography sx={{ mt: { xs: 7, md: 10 }, fontSize: { md: "23px", xs: "16px" } }} variant="body2">
        <b>ToneSuite - Ecocide</b> was designed in collaboration with guitarist Daniël Metselaar from the band Ecocide during the recording sessions of the upcoming <a target="_blank" style={{ textDecoration: "underline" }} href="https://www.youtube.com/watch?v=WPIdYxPPwoc" >Ecocide record</a>. It offers instant crushing guitar and bass tones, that seamlessly glue together into a massive wall of sound.</Typography>
    </Grid >

    <Grid item id="overview" xs={12} md={6} justifyContent={"center"} alignItems={"center"} sx={{ mt: { xs: 5, md: 0 }, pr: { xs: 0, md: 10 } }} >
      <Box sx={{ display: "flex", borderRadius: { xs: 0, md: "20px" }, width: "100%", overflow: "hidden" }}>
        <img loading="lazy" src={ecocideBand} style={{ width: "100%" }} />
      </Box>
    </Grid >
  </Grid >
}

export default OverviewBanner;
