import { Grid, Typography, Box } from "@mui/material";

const SoundDemo = () => {
  return <Grid container sx={{ background: "white" }} justifyContent={"center"}>
    <Grid item xs={12} md={6}>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Typography variant="h3" sx={{ color: "black" }}>
          Take a listen
        </Typography>
        <iframe
          width="100%"
          height="400px"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1912455303&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
        </iframe>
      </Box>
    </Grid>
  </Grid >
}

export default SoundDemo;