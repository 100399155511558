import "./style.scss"
import { useState } from "react";
import { useDispatch } from 'react-redux';

import TopBar from "../../components/TopBar";
import { Box, Button, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";

import BottomBar from "../../components/BottomBar";
import { logIn } from "../../actions/tokenActions";

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSearchParams } from "react-router-dom";
import { StyledTextfield } from "../../components/StyledTextField";
import { Helmet } from "react-helmet";


const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch()
  let [searchParams] = useSearchParams();

  const handleLogin = () => {
    if (username === "" || password === "")
      return;

    let redirect = searchParams.get("redirect");

    if (!redirect)
      redirect = "/account"

    dispatch(logIn(username, password, redirect));
  }

  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>

    <Helmet>
      <title>WaveMind Audio | Login</title>
      <link rel="canonical" href="https://wavemind.net/login" />
    </Helmet>

    <TopBar />
    <Grid container justifyContent={"center"} sx={{ padding: { md: 10, xs: 0 }, flex: 1000, background: "#fff" }}>
      <Grid item xs={12} md={6} lg={4} sx={{ mb: 4 }}>

        <Card sx={{
          borderRadius: { xs: 0, md: 4 },
          padding: { xs: 1, md: 4 },
          pt: { xs: 0, md: 4, },
          pb: { xs: 0, md: 4, },
          background: "#1a1a1a",
          justifyContent: "center",
          alignItems: 'center',
          display: "flex"
        }}>
          <CardContent sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
            <Box sx={{ width: "100%" }} display="flex" alignItems="center" justifyContent={"center"} flexDirection={"column"}>
              {/* <img src={logo} alt="Logo" width="100px" height="100px" /> */}
              <Typography variant="h3">Login</Typography>
              <StyledTextfield
                label="Email"
                variant="outlined"
                required
                size="small"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                sx={{
                  mt: 3
                }}
              />

              <StyledTextfield
                label="Password"
                required
                variant="outlined"
                value={password}
                size="small"
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  mt: 3
                }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment:
                    <IconButton color="primary" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                }}
              />
              <Button
                sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                variant="contained" color="primary"
                onClick={handleLogin}
              >
                Login
              </Button>
              <Box sx={{ mt: 3, width: "100%" }}>

                <Box sx={{ textAlign: "left", width: "100%", display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ fontSize: 15, ml: 0, mr: "auto" }}>
                    <a style={{ textAlign: "left", textDecoration: "underline" }} href="/forgotPassword">Forgot password?</a>
                  </Typography>

                  <Typography sx={{ fontSize: 15, mr: 0, ml: "auto" }}>
                    <a style={{ textAlign: "right", textDecoration: "underline" }} href="register">Create Account</a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
    <BottomBar />
  </div >
}

export default LoginPage;