import { Grid, Card, CardContent, Box, Typography, Button, CircularProgress } from "@mui/material";
import BottomBar from "../../components/BottomBar";
import TopBar from "../../components/TopBar";
import { useState } from "react";
import { StyledTextfield } from "../../components/StyledTextField";
import { useDispatch } from "react-redux";
import { setError, setSuccess } from "../../actions/notificationActions";
import { postContact } from "../../util/api/supportApi";
import EmailHarvesting from "../../components/EmailHarvesting";
import { Helmet } from "react-helmet";

const SupportPage = () => {
  const [text, setText] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false)

  const [textError, setTextError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {

    setTextError(text === "")
    setEmailError(email === "")
    setNameError(name === "")

    if (text === ""
      || email === ""
      || name === ""
    ) {
      return;
    }
    setLoading(true);

    postContact(name, email, text).then(() => {
      setLoading(false);
      setText("")
      setEmail("")
      setName("")
      dispatch(setSuccess("Message received, we will contact you soon!"))
    }).catch((e) => {
      setLoading(false);
      dispatch(setError(e?.response?.data?.detail))
    })

  }

  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <Helmet>
      <title>WaveMind Audio | Contact</title>
      <link rel="canonical" href="https://wavemind.net/contact" />
    </Helmet>
    <TopBar menuValue="3" />
    <Grid container justifyContent={"center"} sx={{ padding: { md: 4, xs: 0 }, flex: 500, background: "white" }}>
      <Grid item xs={12} md={6} lg={4} >
        <Card sx={{
          borderRadius: { xs: 0, md: 4 },
          padding: { xs: 1, md: 4 },
          pt: { xs: 0, md: 4, },
          pb: { xs: 0, md: 4, },
          mb: 3,
          background: "#1a1a1a",
          justifyContent: "center",
          alignItems: 'center',
          display: "flex"
        }}>
          <CardContent sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
            <Box sx={{ width: "100%" }} display="flex" alignItems="left" justifyContent={"center"} flexDirection={"column"}>
              <Typography sx={{ mb: 3 }} variant="h4"><b>Contact Us</b></Typography>
              <Typography>At WaveMind we are happy to help with any questions you have regarding our software. Please fill out the contact form below, and we will do our best to answer as soon as possible!</Typography>

              <StyledTextfield
                id="field1"
                required
                error={nameError}
                label="Name"
                variant="outlined"
                size="small"
                autoComplete="off"
                placeholder="Your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{
                  mt: 3
                }}
              />

              <StyledTextfield
                id="field1"
                label="Email"
                variant="outlined"
                required
                size="small"
                error={emailError}
                autoComplete="off"
                placeholder="Please enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  mt: 3
                }}
              />

              <StyledTextfield
                label="Message"
                multiline
                minRows={7}
                variant="outlined"
                required
                error={textError}
                size="small"
                autoComplete="off"
                placeholder="Your question"
                value={text}
                onChange={(e) => setText(e.target.value)}
                sx={{
                  mt: 3
                }}
              />

              <Button onClick={handleSubmit} sx={{ mt: 3 }} variant="contained">{loading ? <CircularProgress size="23px" sx={{ color: "#000" }} /> : "Send message"}</Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    <EmailHarvesting />
    <BottomBar />
  </div>
}

export default SupportPage;