import { Grid, Typography, Box } from "@mui/material";

import laptop from "../../../static/images/revf/rev_f_standalone.webp"
import asioLogo from "../../../static/images/asio_logo.jpg"

const StandAloneBanner = () => {
  const textColor = "#000"
  return <Grid id="cabBanner" container sx={{ width: "100%", pt: 0, pb: 0, background: "#fff" }} justifyContent={"center"} >

    <Grid item xs={12} md={12} justifyContent={"left"} alignItems={"center"}>
      <Grid container sx={{ width: "100%" }} alignItems={"center"} justifyContent={"center"}>

        <Grid item xs={12} md={6} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, mb: "0 !important", textAlign: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "bold", color: textColor }}>No Dependencies</Typography>
          <Typography fontFamily={"montserrat"} sx={{ mt: 2, fontSize: { md: "24px", xs: "16px", color: textColor } }}>Don't have access to either a VST3, AAX or AU host? No worries, you can still run the amplifier all on its own! </Typography>
          <Typography fontFamily={"montserrat"} sx={{ mt: 2, fontSize: { md: "24px", xs: "16px", color: textColor } }}>A fully standalone version is included with your download. </Typography>
        </Grid>
        <Grid item xs={12} md={12} alignItems={"center"} sx={{ p: { xs: 0, md: 0 }, color: textColor, display: "flex" }} justifyContent={"center"}>
          <img loading="lazy" fetchpriority="low" alt="Amp Suite Rev F Standalone" width="100%" src={laptop} style={{ maxWidth: "700px", display: "block" }} />
        </Grid>
        <Grid item xs={12} md={6} alignItems={"center"} sx={{ p: { xs: 4, md: 10, color: textColor }, mt: "0 !important", textAlign: "left" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", color: textColor }}>System Requirements</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, mt: 2, mb: 2 }}>
            <Box><b>Formats:</b> Amp Suite: Rev F is available in 64-bit VST3, AU, AAX and Standalone format.</Box>
            <Box><b>OS:</b> Windows® 10 64 Bit (latest update) or MacOS® 10.13+</Box >
            <Box><b>Processor:</b> Minimum AVX support (Intel Haswell® or AMD Excavator® and up)</Box >
            <Box><b>Memory:</b> 8 GB</Box >
            <Box><b>Storage:</b> 2GB+ of free space</Box >
            <Box><b>Account:</b> A Free WaveMind account is required to register your license.</Box>
            <Box><b>Internet:</b> An internet connection required to activate your license through your WaveMind account.</Box>
          </Box>
          <Typography fontSize={12}><b>Note: </b>these specs are estimates and are considered the bare minimum. For a better experience, higher specs are recommended.</Typography>
          <img loading="lazy" fetchpriority="low" alt="ASIO" width="100%" src={asioLogo} style={{ maxWidth: "50px", marginTop: "10px" }} />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
}

export default StandAloneBanner;
