import { Dialog, Card, Button, Box, Slide, CardContent, Typography } from "@mui/material"

import * as React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const AccountDialog = ({ open, onClose, text = "A WaveMind account is required to register your purchase" }) => {
  return <Dialog
    open={open}
    onClose={onClose}
    TransitionComponent={Transition}
  >
    <Card sx={{ minWidth: "300px", p: 3, background: "radial-gradient(circle, rgb(60, 60, 60)0%, rgb(30, 30, 30) 100%)" }}>
      <CardContent>
        <Typography sx={{ mb: 4 }}>{text}</Typography>
        <Box sx={{ mt: 0, mb: 1, width: "100%", textAlign: "center" }}> <Typography variant="h6">Account </Typography> </Box>
        <Button sx={{ width: "100%" }} href={`/login?redirect=${window.location.pathname}`} variant="contained">Log In</Button>

        <Box sx={{ mt: 1, mb: 1, width: "100%", textAlign: "center" }}> <Typography variant="subtitle1">or</Typography> </Box>
        <Button sx={{ width: "100%" }} href="/register" variant="contained">Create Account</Button>
      </CardContent>
    </Card>

  </Dialog >
}

export default AccountDialog