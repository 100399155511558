import axios from "axios";

const API_BASE = "/api/user"

export const logIn = async (username: string, password: string) => {

    const payload = new FormData();
    payload.append("username", username);
    payload.append("password", password);

    return await axios.post(
        `${API_BASE}/login`,
        payload
    )
}

export const logOut = async () => {
    return await axios.post(`/api/user/logout`)
        .then(() => console.log('successfully logged out'))
        .catch((e: any) => console.log('error while logging out: ', e))
}

export const registerUser = async (username: string, displayName: string, password: string, confirmPassword: string, newsCheck: boolean) => {

    const data = {
        email: username,
        password: password,
        password_confirm: confirmPassword,
        display_name: displayName,
        allow_marketing: newsCheck
    }

    return await axios.post(
        `${API_BASE}/register`,
        data
    )
}

export const requestPasswordReset = async (email: string) => {
    const data = {
        email: email
    };

    return axios.post(`${API_BASE}/resetrequest`, data)
}

export const resetPassword = async (resetKey: string, password: string, confirmPassword: string) => {
    const data = {
        reset_key: resetKey,
        password: password,
        confirm_password: confirmPassword
    }

    return axios.post(`${API_BASE}/resetpassword`, data)
}

export const changePassword = async (currentPassword: string, newPassword: string, newPasswordConfirm: string) => {
    const data = {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_repeat: newPasswordConfirm
    }

    return axios.post(`${API_BASE}/changepassword`, data)
}

export const updateUser = async (email: string, name: string) => {
    const data = {
        email: email,
        name: name,
    }

    return axios.post(`${API_BASE}/update`, data)
}

export const getUserLicenses = () => {
    return axios.get(`${API_BASE}/licenses`)
}

export const getUserOrders = () => {
    return axios.get(`${API_BASE}/orders`)
}

export const fetchToken = () => axios.get('/api/token/');

export const refreshToken = () => axios.post('/api/token/refresh')

