import "./Home.scss";

import React, { useEffect, useState } from "react";

import RevFAmplifierSuitePage from "../Products/Rev F";
import EcocideAmplifierSuitePage from "../Products/EcocideAmp"
import { Helmet } from 'react-helmet';
import StorePage from "../Store";
import TopBar from "../../components/TopBar";
import { Box, Grid } from "@mui/material";
import RevFFrontpageTile from "../../components/Products/RevFAmplifierSuite/FrontpageTile";
import ProToneFrontpageTile from "../../components/Products/Protone/FrontpageTile";
import EcoFrontpageTile from "../../components/Products/CNAmp/FrontpageTile";

const Home: React.FC = () => {

  return (
    <>
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        {/* <RevFAmplifierSuitePage useHelmet={false} /> */}
        <Helmet>
          <title>WaveMind Audio | Specialist in Guitar Software and Plugins</title>
          <link rel="canonical" href="https://wavemind.net/" />
        </Helmet>
        <TopBar menuValue="0" />

        <Grid container sx={{ display: "flex" }}>
          <Grid xs={12} md={6}>
            <Box sx={{ height: { xs: "100%", md: "calc(100vh - 64px)" } }}>
              <RevFFrontpageTile />
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Grid>
              <Box sx={{ height: { xs: "100%", md: "calc(50vh - 32px)", overflow: "hidden" } }}>
                <ProToneFrontpageTile />
              </Box>
              <Box sx={{ height: { xs: "100%", md: "calc(50vh - 32px)", overflow: "hidden" } }}>
                <EcoFrontpageTile />
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </div>
      <StorePage useHelmet={false} embedded={true} />
    </>);
};

export default Home;
