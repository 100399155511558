import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import ampPic from "../../../static/images/cn_amp/plugin_ui.webp"
import ampvid from "../../../static/images/cn_amp/eco_web_bg.mp4"
import ampvidMobile from "../../../static/images/cn_amp/eco_web_bg_mobile.mp4"
import thumbnail from "../../../static/images/cn_amp/eco_thumbnail.webp"
import thumbnailMobile from "../../../static/images/cn_amp/eco_thumbnail_mobile.webp"

const Image = () => {
  return <img fetchpriority="high" alt="Amp Suite Rev F" src={ampPic} width="100%" style={{ maxWidth: "400px" }}></img>
}

const EcoFrontpageTile = () => {

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < window.innerHeight
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        m: 0,
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: { xs: "block", md: "flex" },
        flexDirection: "column",
        flexGrow: 10,
        height: "100%",
        width: " 100%",
        overflow: "hidden"
      }}
    >
      <Box sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden"
      }}>
        <video style={{
          minWidth: "100%",
          minHeight: "100%",
          height: "auto",
          width: "auto",
          position: "absolute",
          zIndex: -1,
          objectFit: "cover",
          backgroundColor: "black",
          pointerEvents: "none",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)"
        }}
          poster={state.mobileView ? thumbnailMobile : thumbnail}
          preload="metadata"
          muted defaultMuted autoPlay playsInline loop oncontextmenu="return false;" onEnded={() => setAnimationDone(true)}>
          <source src={state.mobileView ? ampvidMobile : ampvid} type="video/mp4"></source>
        </video>
      </Box>
      <Box sx={{ p: 2 }}>
        <Image />
        <Typography
          variant="h3"
          fontSize={30}
          sx={{ fontSize: 30, }}
        >
          Tone Suite - Ecocide
        </Typography>
        <Typography
          // fontFamily={"pirulen"}
          style={{
            fontSize: state.mobileView ? "15px" : "20px",
            // fontWeight: 500,
          }}
        >
          Instant brutality.
        </Typography>
        <Button
          sx={{
            mt: 4,
            background: "white",
            color: "black", width: { xs: "75%", md: "150px" }
          }}
          variant="outlined"
          onClick={() => {
            window.location.href = "/product/ecocide-amplifier-suite"
          }}
        >
          Learn More
        </Button>
      </Box>
    </Box>
  );
}

export default EcoFrontpageTile;