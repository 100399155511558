import { Grid, Typography, Box } from "@mui/material";

import cabVideo from "../../../static/images/cn_amp/ir_vid.mp4"
import outputControls from "../../../static/images/cn_amp/Output_section.png"

const CabinetBanner = () => {
  const textColor = "#000"
  return <Box textAlign="center" justifyContent="center" id="cabBanner" container sx={{ width: "100%", pt: { xs: 0, md: 10 }, pb: { xs: 4, md: 10 }, background: "radial-gradient(circle, rgb(204, 204, 214) 0%, rgb(86, 86, 105) 100%)" }}  >
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6}>

        <Box sx={{ display: "flex", width: "100%", borderRadius: { xs: 0, md: "30px" }, overflow: "hidden" }}>
          <video
            muted defaultMuted autoPlay playsInline loop oncontextmenu="return false;" style={{ width: "100%", height: "100%" }} >
            <source src={cabVideo} type="video/mp4"></source>
          </video>
        </Box>
        <Typography variant="h3" sx={{ mt: { xs: 2, md: 5 }, color: "black" }}>
          Blend Your Cabs
        </Typography>
        <Typography sx={{ textAlign: { xs: "left", md: "center" }, pl: { xs: 4, md: 0 }, pr: { xs: 4, md: 0 }, mt: { xs: 2, md: 3 }, fontSize: { md: "23px", xs: "16px", color: "black" } }} variant="body2">
          Discover our mix ready IR files in the intuitive WaveMind Cabinet Loader (WMCL). Create your own blends between IR files, or load your own custom IRs.
        </Typography>
      </Grid>
    </Grid>
  </Box >
}

export default CabinetBanner;
