import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import BottomBar from "../../components/BottomBar";
import TopBar from "../../components/TopBar";
import { StyledTextfield } from "../../components/StyledTextField";

import { useState } from "react";
import { setError, setSuccess } from "../../actions/notificationActions";
import { useDispatch } from "react-redux";
import AccountDialog from "../../components/AccountDialog";
import { LoggedIn, LoggedOut } from "../../components/AccessControl";
import { claimLicense } from "../../util/api/licenseApi";

import { Helmet } from 'react-helmet';

const isUUID = (str) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
}

const KeyClaimPage = ({ useHelmet = true }) => {

  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setLoading(true)
    claimLicense(key).then((resp) => {
      dispatch(setSuccess(resp.data))
      setKey("")
      setLoading(false)
    }).catch((e) => {
      setLoading(false)
      dispatch(setError(e.response.data.detail))
    })

  }

  return (
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      {useHelmet && <Helmet>
        <title>WaveMind Audio | Claim Your License</title>
        <link rel="canonical" href="https://wavemind.net/claim" />
      </Helmet>}
      <AccountDialog
        text={"A WaveMind account is required to register your license"}
        open={accountDialogOpen}
        onClose={() => { setAccountDialogOpen(false) }} />
      <TopBar />
      <Grid container justifyContent={"center"} sx={{ padding: { md: 10, xs: 0 }, flex: 500, background: "white" }}>
        <Grid item xs={12} md={6} lg={4} sx={{ mb: 4 }}>
          <Card sx={{
            borderRadius: { xs: 0, md: 4 },
            padding: { xs: 1, md: 4 },
            pt: { xs: 0, md: 4, },
            pb: { xs: 0, md: 4, },
            background: "#1a1a1a",
            justifyContent: "center",
            alignItems: 'center',
            display: "flex"
          }}>

            <CardContent sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
              <Box sx={{ width: "100%" }} display="flex" alignItems="center" justifyContent={"center"} flexDirection={"column"}>
                <Typography>Enter the License you want to claim.</Typography>
                <StyledTextfield
                  id="field1"
                  label="License Key"
                  variant="outlined"
                  required
                  size="small"
                  autoComplete="off"
                  placeholder="Please enter your license key"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  sx={{
                    mt: 3
                  }}
                />

              </Box>
              <LoggedIn>
                <Button
                  sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!isUUID(key)}
                >
                  {loading ? <CircularProgress size="23px" sx={{ color: "#000" }} /> : "Claim"}
                </Button>
              </LoggedIn>
              <LoggedOut>
                <Button
                  sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                  color="primary"
                  variant="contained"
                  onClick={() => { setAccountDialogOpen(true) }}
                  disabled={!isUUID(key)}
                >
                  {loading ? <CircularProgress size="23px" sx={{ color: "#000" }} /> : "Claim"}
                </Button>
              </LoggedOut>
            </CardContent>

          </Card>
        </Grid>
      </Grid>
      <BottomBar />
    </div>
  )
}


export default KeyClaimPage; 