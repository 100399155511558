// @ts-nocheck
/* eslint-disable */
import axios from "axios";

import { refreshToken } from './api/userApi';
import { logOut, logOutAndLogin } from '../actions/tokenActions';

import { useSelector } from 'react-redux';

const MUST_BE_USER = "Must be user";
const NOT_EXISTS = "User or customer does not exist";
const UNAUTHORIZED_FOR_USER = "Unauthorized for user"

export const initInterceptor = (dispatch: any, store: any) => {
  var prevURL: string | null = null;

  axios.interceptors.request.use((config) => {
    const state = store.getState();
    const headers = {
      'Authorization': `Bearer ${state.token?.raw?.access_token}`
    }

    config.headers = headers;

    return config;
  })

  axios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {

      const reject_ = () => new Promise((resolve, reject) => {
        reject(error);
      });

      const retry_ = () => new Promise((resolve, reject) => {
        axios.request(error.config)
          .then((resp: any) => resolve(resp))
          .catch((e: any) => reject(e));
      })

      if (error?.response?.status === 400) {
        console.error(400)
      }

      // Ignore any non 401 errors
      if (error?.response?.status !== 401) {
        return reject_()
      }

      if (error.config.url === '/api/user/token/refresh') {
        dispatch(logOutAndLogin())
        return reject_();
      }

      /* prevent interceptor from looping. */
      if (error.config.url === prevURL) {
        prevURL = null;
        return reject_();
      }

      prevURL = error.config.url;

      return refreshToken()
        .then((result) => {
          dispatch(setToken(result.data))
          return retry_
        }
        )
        .catch(() => {
          console.log("Logging out")
          dispatch(logOutAndLogin());
          return reject_()
        })
    }
  )
}
