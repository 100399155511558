import { Avatar, Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import BottomBar from "../../components/BottomBar"
import TopBar from "../../components/TopBar"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import SaveIcon from '@mui/icons-material/Save';
import { changePassword, getUserLicenses, updateUser } from "../../util/api/userApi";
import { setError, setSuccess } from "../../actions/notificationActions";
import { setLoading, setToken } from "../../actions/tokenActions";
import KeyIcon from '@mui/icons-material/Key';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { deleteLicenseActivation } from "../../util/api/licenseApi";
import { StyledTextfield } from "../../components/StyledTextField";
import ProductDownload from "../../components/ProductDownload";

import AddIcon from '@mui/icons-material/Add';
import LicenseClaimDialog from "../../components/LicenseClaimDialog/LicenseClaimDialog";

import { Helmet } from 'react-helmet'

export const EditTextField = withStyles({
  input: {
    color: "#000"
  },
  root: {
    '& label.Mui-focused': {
      color: '#555',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#555',
        color: "black"
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#555',
      },
    },
    width: "300px",
  },
})(TextField);

const PasswordDialog = ({ open, onClose }) => {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [passwordError, setPasswordError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState(false)

  const dispatch = useDispatch()

  const handleConfirm = () => {
    setPasswordError(currentPassword === "");
    setNewPasswordError(newPassword === "");
    setNewPasswordConfirmError(newPasswordConfirm === "" || newPasswordConfirm !== newPassword);

    if (currentPassword === ""
      || newPassword === ""
      || newPasswordConfirm === "" || newPasswordConfirm !== newPassword
    ) {
      return;
    }

    changePassword(currentPassword, newPassword, newPasswordConfirm).then(() => {
      dispatch(setSuccess("Password changed!"))
      onClose()
    }).catch((e) => {
      dispatch(setError(e?.response?.data?.detail))
    })
  }

  return <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogTitle>Change Password</DialogTitle>
    <DialogContent>
      <Box sx={{ spacing: 3, display: "flex", flexDirection: "column" }}>
        <StyledTextfield
          sx={{ mt: 2 }}
          label="Current Password"
          size="small"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          type="password"
          placeholder="Provide your current password"
          required
          error={passwordError}
        />
        <StyledTextfield
          sx={{ mt: 2 }}
          label="New Password"
          size="small"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
          placeholder="Provide your current password"
          required
          error={newPasswordError}
        />
        <StyledTextfield
          sx={{ mt: 2 }}
          label="Confirm New Password"
          size="small"
          value={newPasswordConfirm}
          onChange={(e) => setNewPasswordConfirm(e.target.value)}
          type="password"
          placeholder="Provide your current password"
          required
          error={newPasswordConfirmError}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={handleConfirm}>Confirm</Button>
    </DialogActions>
  </Dialog>
}

const SettingDisplay = ({ title, value, editMode, onChange, passwordField }) => {

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)


  return <Grid container sx={{ mb: 5 }}>
    <PasswordDialog open={passwordDialogOpen} onClose={() => { setPasswordDialogOpen(false) }} />
    <Grid item xs={4} md={2}><Typography variant="body1"><b>{title}</b></Typography></Grid>
    <Grid item xs={8} md={10}>
      {editMode || <Typography sx={{ paddingLeft: "10px" }}>{value}</Typography>}
      {editMode && !passwordField && <Typography sx={{ color: "black" }}>
        <EditTextField
          inputProps={{ style: { color: "#000", padding: 0, paddingLeft: 10 } }}
          sx={{ color: "black" }}
          value={value}
          size="small"
          onChange={onChange}
        />
      </Typography>}
      {editMode && passwordField &&
        <Button
          onClick={() => { setPasswordDialogOpen(true) }}
          startIcon={<LockIcon />}
          size="small"
          variant="contained"
          sx={{ color: "black", borderColor: "black", background: "white" }}>
          Change Password
        </Button>

      }
    </Grid>
  </Grid>
}

const LicenseList = ({ licenses, onReload }) => {
  const [showActivations, setShowActivations] = useState(undefined);
  const [downloadID, setDownloadID] = useState(undefined);

  const dispatch = useDispatch();

  const deleteActivation = (licenseID, activationID) => {
    deleteLicenseActivation(licenseID, activationID).then(() => {
      dispatch(setSuccess("Removed Activation"))
      onReload();
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }

  return <Box>
    <ProductDownload open={downloadID !== undefined} onClose={() => setDownloadID(undefined)} productID={downloadID} />
    {licenses.map((item) => {
      return <Box sx={{ mb: 2, borderRadius: "8px", border: "1px solid black" }}>
        <Box sx={{ p: 1, mb: 2, display: " flex", flexDirection: "row", alignItems: "center", columnGap: 2 }}>
          <Avatar sx={{ background: "black", color: "white" }}><KeyIcon /></Avatar>
          <Box>
            <Typography variant="h6"><b>{item?.product_name}</b></Typography>
            <Typography sx={{ textTransform: "uppercase" }} variant="body1">{item?.id}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", columnGap: 2, p: 1 }}>
          <Button startIcon={<DownloadIcon />} color="secondary" variant="contained" sx={{ background: "#fff" }}
            onClick={() => setDownloadID(item?.product_id)}
          >Download</Button>
          <Button
            onClick={() => {
              if (showActivations !== item.id)
                setShowActivations(item.id)
              else
                setShowActivations(undefined)
            }
            }
            endIcon={showActivations === item.id ? <ExpandLessIcon /> : <ExpandMoreIcon />} color="secondary" variant="contained" sx={{ background: "#fff" }}>{item?.activations?.length} of {item?.max_licenses} devices activated</Button>
        </Box>
        <Collapse in={showActivations === item.id}>
          <Box sx={{ background: "#ddd", borderBottomLeftRadius: "8px", borderBottomRightRadius: 8 }}>
            <Divider />
            <Box sx={{ p: 1 }}>
              <Grid container>
                <Grid item xs={5} sx={{ pl: { xs: 0, md: 3 } }}><Typography sx={{ fontSize: { xs: 12, md: 15 } }} variant="body1"><b>Device</b></Typography></Grid>
                <Grid item xs={0} md={5} sx={{ pl: { xs: 0, md: 3 }, display: { xs: "none", md: "flex" } }}><Typography sx={{ fontSize: { xs: 12, md: 15 } }} variant="body1"><b>Date registered</b></Typography></Grid>
              </Grid>
            </Box>
            <Divider />
            {item.activations.map((activation) => {
              return <>
                <Box sx={{ p: 1, pt: 2, pb: 2 }}>
                  <Grid container alignItems="center">
                    <Grid item xs={8} md={5} sx={{ pl: { xs: 0, md: 3 } }}>
                      <Typography sx={{ fontSize: { xs: 12, md: 15 } }}>{activation?.machine_name}</Typography>
                    </Grid>
                    <Grid item xs={0} md={5} sx={{ pl: { xs: 0, md: 3 }, display: { xs: "none", md: "flex" } }}>
                      <Typography sx={{ fontSize: { xs: 12, md: 15 } }}>{activation?.updated_at.split("T")[0]}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={() => { deleteActivation(item.id, activation.id) }} sx={{ background: "white" }} size="small" variant="contained">Deactivate</Button>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </>
            })}

          </Box>
        </Collapse>
      </Box >
    })}
  </Box >
}

const AccountPage = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [editMode, setEditMode] = useState(false)
  const [loadingLicenses, setLoadingLicenses] = useState(true)
  const [licenses, setLicenses] = useState([])
  const [licenseClaimOpen, setLicenseClaimOpen] = useState(false);

  const state = useSelector((state) => state)
  const dispatch = useDispatch();

  const canSave = () => {
    return (email !== state?.token?.data?.username
      || name !== state?.token?.data?.display_name
    )
  }

  const handleSave = () => {
    if (!canSave())
      return;

    updateUser(email, name).then((resp) => {
      dispatch(setToken(resp.data))
      dispatch(setSuccess("Information updated!"))
      setEditMode(false)
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }

  const handleLicenseReload = () => {
    fetchLicenses();
  }

  const fetchLicenses = () => {
    getUserLicenses().then((resp) => {
      setLoadingLicenses(false)
      setLicenses(resp.data)
    }).catch((e) => {
      setLoadingLicenses(false)
      dispatch(setError("Could not fetch license information"))
    })
  }

  useEffect(() => {
    setEmail(state?.token?.data?.username)
    setName(state?.token?.data?.display_name)
  }, [state?.token])

  useEffect(() => {
    if (loadingLicenses) {
      fetchLicenses();
    }
    //eslint-disable-next-line
  }, [loadingLicenses])


  const handleNewLicense = () => {
    setLicenses([])
    setLoadingLicenses(true)
    setLicenseClaimOpen(false);
  }

  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <Helmet>
      <title>WaveMind Audio | Account</title>
      <link rel="canonical" href="https://wavemind.net/account" />
    </Helmet>
    <TopBar />
    <Grid container justifyContent={"center"} sx={{ padding: { md: 4, xs: 1 }, flex: 500, background: "white" }}>
      <Grid item xs={12} md={8} sx={{ mb: 4 }}>
        <Box sx={{ color: "#000" }}>
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Typography variant="h4">Profile</Typography>
            {editMode || <Button sx={{ ml: "auto", mr: 0, height: "30px", background: "white" }} size="small" onClick={() => setEditMode(true)} variant="contained" startIcon={<EditIcon />}> Edit</Button>}
            {editMode && <Box sx={{ ml: "auto", mr: 0, }}>
              <Button sx={{ height: "30px", mr: 3, background: "white" }} size="small" onClick={() => setEditMode(false)} variant="contained">
                Cancel
              </Button>

              <Button disabled={!canSave()} sx={{ height: "30px", background: "white" }} size="small" onClick={handleSave} variant="contained" startIcon={<SaveIcon />}>
                Save
              </Button>
            </Box>}
          </Box>
          <Divider sx={{ mt: 1, mb: 3 }} />
          <SettingDisplay title="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} editMode={editMode} />
          <SettingDisplay title="Name" value={name} onChange={(e) => setName(e.target.value)} editMode={editMode} />
          <SettingDisplay title="Password" value={"••••••••"} editMode={editMode} passwordField />
        </Box>

        <Box sx={{ color: "#000", mb: 5 }}>
          <LicenseClaimDialog open={licenseClaimOpen} onLicenseAdded={handleNewLicense} onCancel={() => setLicenseClaimOpen(false)} />
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Typography id="licenses" variant="h4">Licenses</Typography>
            <Button sx={{ ml: "auto", mr: 0, height: "30px", background: "white" }} size="small" onClick={() => setLicenseClaimOpen(true)} variant="contained" startIcon={<AddIcon />}> Add License</Button>
          </Box>
          <Divider sx={{ mt: 1, mb: 3 }} />
          {!loadingLicenses && licenses.length === 0 && <Typography>No licenses found </Typography>}
          {!loadingLicenses && licenses.length === 0 && <Typography sx={{ mt: 2, textDecoration: "underline" }}><a href="/store">Visit Store</a> </Typography>}
          {loadingLicenses && <CircularProgress sx={{ color: "black" }} />}
          {licenses.length !== 0 && <LicenseList licenses={licenses} onReload={handleLicenseReload} />}
        </Box>
      </Grid>
    </Grid>
    <BottomBar />
  </div>
}

export default AccountPage;