import { Box, Divider, Grid, IconButton, Link, Typography } from "@mui/material";

import visaLogo from "../static/paymentIcons/1.png"
import mastercardLogo from "../static/paymentIcons/2.png"
import amexLogo from "../static/paymentIcons/22.png"
import dinersclubLogo from "../static/paymentIcons/10.png"
import discoverLogo from "../static/paymentIcons/14.png"
import idealLogo from "../static/paymentIcons/ideal-logo.svg"
import bancontactLogo from "../static/paymentIcons/bancontact-logo.png"
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { useEffect, useState } from "react";

const PaymentLogo = ({ src, alt, href }) => {
  return <Box sx={{ mr: 2 }}>
    <a rel="noreferrer" target="_blank" href={href}><img loading="lazy" style={{ borderRadius: 0 }} height="30px" src={src} alt={alt} /></a>
  </Box>
}

const BottomBar = () => {

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const defaultSubHeader = () => {
    return <Box >
      <Typography variant="caption">KvK: 90110706</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", float: "right" }}>
        <PaymentLogo src={idealLogo} alt="iDEAL" href="https://www.ideal.nl" />
        <PaymentLogo src={bancontactLogo} alt="Bancontact" href="https://www.bancontact.com/" />
        <PaymentLogo src={visaLogo} alt="Visa" href="https://visa.com" />
        <PaymentLogo src={mastercardLogo} alt="Mastercard" href="https://mastercard.com" />
        <PaymentLogo src={amexLogo} alt="American Express" href="https://www.americanexpress.com/" />
        <PaymentLogo src={dinersclubLogo} alt="Diners Club" href="https://www.dinersclub.com/" />
        <PaymentLogo src={discoverLogo} alt="Discover" href="https://www.discover.com/" />

      </Box>
    </Box>
  }

  const mobileSubHeader = () => {
    return <Box sx={{ display: "flex", spacing: 4, rowGap: 1, flexDirection: "column" }}>
      <Typography variant="caption">KvK: 90110706</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", float: "right", overflow: "scroll" }}>
        <PaymentLogo src={idealLogo} alt="iDEAL" href="https://www.ideal.nl" />
        <PaymentLogo src={bancontactLogo} alt="Bancontact" href="https://www.bancontact.com/" />
        <PaymentLogo src={visaLogo} alt="Visa" href="https://visa.com" />
        <PaymentLogo src={mastercardLogo} alt="Mastercard" href="https://mastercard.com" />
        <PaymentLogo src={amexLogo} alt="American Express" href="https://www.americanexpress.com/" />
        <PaymentLogo src={dinersclubLogo} alt="Diners Club" href="https://www.dinersclub.com/" />
        <PaymentLogo src={discoverLogo} alt="Discover" href="https://www.discover.com/" />

      </Box>
    </Box>
  }

  return <Grid container spacing={4} justifyContent="center" sx={{ p: 4, pt: 0, fontSize: "13px", background: "black" }}>
    <Grid item xs={12} md={8}>
      <Grid container spacing={4}>
        <Grid item sx={{ mt: 2 }} xs={12} md={4}>
          <Typography variant="h5"><b>About WaveMind</b></Typography>
          <Box display="flex" flexDirection={"column"}>
            {/* <Link href="/about">About</Link> */}
            <Link href="/contact">Contact</Link>
            <Link href="/support">Support</Link>

            <Box sx={{ display: "flex", p: 0 }}>
              <IconButton href="https://www.facebook.com/people/WaveMind/100092637661925/" sx={{ pl: 0, m: 0, color: "white" }}><FacebookIcon /></IconButton>
              <IconButton href="https://www.instagram.com/wavemindnl/" sx={{ color: "white" }}><InstagramIcon /></IconButton>
              <IconButton href="https://www.youtube.com/channel/UC9lKMgbHdEOcKXzS5UJT-GQ" sx={{ color: "white" }}><YouTubeIcon /></IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item sx={{ mt: 2 }} xs={12} md={4}>
          <Typography variant="h5"><b>Software</b></Typography>
          <Box display="flex" flexDirection={"column"}>
            <Link href="/store">Store</Link>
            <Link href="/store#free">Free Plugins</Link>
            <Link href="/claim">Claim License Key</Link>
            <Link href="/product/rev-f-amplifier-suite">Amp Suite Rev F</Link>
          </Box>
        </Grid>
        <Grid item sx={{ mt: 2 }} xs={12} md={4}>
          <Typography variant="h5"><b>Legal</b></Typography>
          <Box display="flex" flexDirection={"column"}>
            <Link href="/terms">Terms of Service</Link>
            <Link href="/privacy">Privacy Policy</Link>
            <Link href="/refund">Refund Policy</Link>
          </Box>
        </Grid>
      </Grid>
      <Divider color="white" sx={{ mt: 2, mb: 2 }} />
      {state.mobileView ? mobileSubHeader() : defaultSubHeader()}
    </Grid>
  </Grid>
}

export default BottomBar;
