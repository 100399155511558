import { Typography, Skeleton, Tooltip, IconButton } from "@mui/material"

import InfoIcon from '@mui/icons-material/Info';
import { Box } from "@mui/system";

const toEuroString = (val) => {
  return `€${(val / 100).toFixed(2)}`
}

const PriceComponent = ({ product, hideVAT }) => {
  return (
    <Typography sx={{ display: "flex", mt: 2, fontWeight: "bold", fontSize: { xs: "30px", md: "40px" }, justifyContent: "center", alignItems: "center", gap: 2 }}>

      {product?.metadata?.original_price && <Typography textAlign="center" sx={{ fontWeight: 300, fontSize: { xs: "20px", md: "30px" }, color: "#dddddd", textDecoration: "line-through" }}>
        {toEuroString(product?.metadata?.original_price)}
      </Typography>}

      {product && toEuroString(product?.default_price?.unit_amount)}
      {product?.default_price?.unit_amount === undefined && <Skeleton variant="text" width={210} />}
      {hideVAT || <Tooltip title="For EU customers, VAT will be added on checkout."><IconButton sx={{ color: "rgb(249, 245, 238)", width: "24px", height: "24px" }}><InfoIcon /></IconButton></Tooltip>}
    </Typography >
  )
}

const PriceContent = ({ name, product, hideVAT }) => {
  return <Box sx={{
    p: { xs: 0, md: 2 },
    display: "flex",
    flexDirection: "column",
    columnGap: 2,
    color: "#fff",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left"
  }}>
    <Typography variant="caption">
      {name}
    </Typography>
    <Box sx={{ display: "flex", flexDirection: "row", columnGap: 1, alignItems: "center" }}>

      {product?.metadata?.original_price && <Typography textAlign="center" sx={{ fontWeight: 300, color: "#dddddd", textDecoration: "line-through" }}>
        {toEuroString(product?.metadata?.original_price)}
      </Typography>}
      <Typography sx={{ fontWeight: "bold", textAlign: "left", width: "100%" }}>
        {product && toEuroString(product?.default_price?.unit_amount)}
        {product?.default_price?.unit_amount === undefined && <Skeleton variant="text" width={100} />}
      </Typography>
      {hideVAT || <Tooltip title="For EU customers, VAT will be added on checkout."><IconButton sx={{ color: "rgb(249, 245, 238)", width: "16px", height: "20px" }}><InfoIcon sx={{ width: "20px" }} /></IconButton></Tooltip>}

    </Box>
  </Box>
}

export { PriceContent };
export default PriceComponent