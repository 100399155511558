import React from "react";
import { Provider } from 'react-redux';

import { initInterceptor } from './util/interceptor'
import Theme from "./Theme/Themes";

import "./App.scss";
import AppRouter from "./AppRouter";
import StorageProvider from "./Store"
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import NotificationComponent from "./components/ErrorComponent";

const App: React.FC = () => {
  const { store } = StorageProvider();

  initInterceptor(store.dispatch, store);

  return (

    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <NotificationComponent />
        <AppRouter />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
