import { Grid, Typography, Box, Card, CardContent } from "@mui/material";

import ampImage from "../../../static/images/revf/rev_f_amp.webp"
import ampImageBackside from "../../../static/images/revf/rev_f_amp_backside.webp"

import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'


const TEXT_COLOR = "#fff";

const Paragraph = ({ title, children, smallTitle }) => {
  return <Box sx={{ mt: 2, textAlign: "left" }}>

    <Typography sx={{ fontSize: { md: "23px", xs: "16px" } }} variant="body2" ><b>{title}:</b> {children}</Typography>
  </Box>
}

const AmplifierBanner = () => {
  return <Grid container sx={{
    width: "100%",
    pt: "80px",
    pb: 0,
    minHeight: "100vh",
    // background: `url(${ampImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: "#1a1a1a"
  }}

    justifyContent={"center"} >

    <Grid id="ampBanner" item xs={12} md={12} justifyContent={"center"} alignItems={"center"}>
      <Grid container sx={{ width: "100%" }} alignItems={"center"} justifyContent={"center"}>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ p: { xs: 4, md: 4 }, pb: "0 !important", color: TEXT_COLOR, }}>
          <Typography variant="h2" sx={{ fontWeight: "bold", textAlign: "center", }}>The Amplifier</Typography>
        </Grid>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ mt: 5, pl: { xs: 0, md: 10, }, pr: { xs: 0, md: 10, }, color: TEXT_COLOR, }}>
          <Box sx={{ width: { xs: "100vw", md: "100%", } }}>
            <img fetchpriority="low" alt="Amp Suite Rev F" src={ampImage} style={{ width: "100%" }} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, paddingTop: "0 !important", color: TEXT_COLOR, }}>

          <Typography fontFamily={"montserrat"} sx={{ mt: 2, fontSize: { md: "30px", xs: "16px", textAlign: "center" } }} >
            Powered by our new modelling engine, this amp sim closely recreates this 90's classic amplifier.
          </Typography>
        </Grid>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, paddingTop: "0 !important", color: TEXT_COLOR, }}>

          <Typography fontFamily={"montserrat"} sx={{ mt: 2, fontSize: { md: "30px", xs: "16px", textAlign: "center" } }} >
            <b>Every mode available:</b>

            <Typography fontFamily={"montserrat"} sx={{ mt: 6, fontSize: { md: "30px", xs: "16px", textAlign: "center" } }} >

              <Paragraph title="Channel Select">Green, Orange and Red channels available; from clean to high gain modern tones.</Paragraph>
              <Paragraph title="Channel Cloning">Make either channel have a modern or vintage sound.</Paragraph>
              <Paragraph title="Tube Select">Choose between 6L6 and EL34 output tubes.</Paragraph>
              <Paragraph title="Power Select">Choose wether or not to run in low power mode, to emulate the use of a variac.</Paragraph>
              <Paragraph title="Rectifier Select">Choose between old school saggy tube rectifiers, or diode rectifiers for a tight and modern sound.</Paragraph>
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ mt: 0, pl: { xs: 0, md: 10, }, pr: { xs: 0, md: 10, }, color: TEXT_COLOR, }}>
          <Box sx={{ width: { xs: "100vw", md: "100%", } }}>
            <img fetchpriority="low" alt="Amp Suite Rev F Back side" src={ampImageBackside} style={{ width: "100%" }} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, paddingTop: "0 !important", color: TEXT_COLOR, }}>

          <Typography fontFamily={"montserrat"} sx={{ mt: 2, fontSize: { md: "30px", xs: "16px", textAlign: "center" } }} >
            Curiuous what it sounds like?

          </Typography>
        </Grid>


        <Grid item xs={12} md={6} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, paddingTop: "0 !important", color: TEXT_COLOR, }}>
          <Card sx={{ width: "100%", borderRadius: "10px", }}>
            <CardContent sx={{ background: "none", padding: 0, paddingBottom: "0 !important" }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                  {/* <Typography sx={{ mb: 2 }} variant="h5" >Full Mix</Typography>
                  <audio style={{ width: "100%" }} src={demoMixFull} controls /> */}

                  <LiteYouTubeEmbed
                    id="qLGzEyvsZes"
                    title="This Rev F Dual Rectifier Plugin Is Ridiculous!"
                    frameborder="0"
                    poster="maxresdefault"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    noCookie={true}
                    webp={true}
                  />

                </Grid>
                {/* <Grid item xs={12} md={12}>
                  <Typography sx={{ mb: 2 }} variant="h5">Raw Guitar</Typography>
                  <audio style={{ width: "100%" }} src={rawGuitar} controls />
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </Grid>
  </Grid >
}

export default AmplifierBanner;
