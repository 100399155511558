import "./mainbanner.scss"
import { Box, Grid, Button, CircularProgress, Typography, Skeleton, IconButton, Tooltip } from "@mui/material";

import ampPic from "../../../../static/images/protone/Cor.webp"
import ampvid from "../../../../static/images/revf/rev_f_video_desktop.mp4"
import ampvidMobile from "../../../../static/images/revf/rev_f_video_mobile.mp4"

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import InfoIcon from '@mui/icons-material/Info';

import bg from "../../../../static/images/protone/midboost_bg.webp"

import { useEffect, useState } from "react";
import BuyButton from "../../../BuyButton";
import PriceComponent from "../../../PriceComponents";

const Image = () => {
  return <img fetchpriority="high" alt="Cor Mid Boost" src={ampPic} width="100%" style={{ maxWidth: "600px" }}></img>
}

const MainBanner = ({ price, product, onTrial }) => {

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < window.innerHeight
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        p: 0,
        m: 0,
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: { xs: "block", md: "flex" },
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <img
        src={bg}
        style={{

          width: "100%",
          height: "100%",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -1,
          objectFit: "cover",
          backgroundColor: "black",
          pointerEvents: "none"
        }} preload="auto" />


      <Box sx={{
        position: "absolute",
        fontSize: 50,
        color: "white",
        href: "#ampBanner",
        variant: "outlined",
        bottom: { xs: 180, md: 100 },
        left: 0,
        right: 0,
        mr: "auto",
        ml: "auto",

      }}
        className={animationDone ? "fadeOut" : "fadeIn"}
      >
        <IconButton
          href="#ampBanner"
          sx={{
            color: "white",
          }}
        >
          <ExpandMoreIcon sx={{ fontSize: 50 }} />
        </IconButton>
      </Box>

      <Grid container justifyContent={"center"} className={animationDone ? "fadeIn" : "fadeOut"}>
        <Grid xs={12} md={8} sx={{ background: "none", p: 1, borderRadius: 10, }}>
          <Image />
          {/* <Box sx={{ minHeight: "100vh" }} /> */}
          <Typography
            variant="h2"
            fontSize={20}
            sx={{ fontSize: 20, }}
          >
            Cor Mid Boost
          </Typography>
          <Typography
            // fontFamily={"pirulen"}
            style={{
              fontSize: state.mobileView ? "20px" : "30px",
              // fontWeight: 500,
            }}
          >
            Cut through the Mix.
          </Typography>

          <PriceComponent product={product} hideVAT />
          <Box sx={{ mt: 3, columnGap: 5, rowGap: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: "center" }}>
            <Button
              sx={{ width: { xs: "75%", md: "150px" } }}
              variant="outlined"
              onClick={() => {
                const element = document.getElementById("ampBanner");
                if (element) {
                  let y = element?.getBoundingClientRect()?.top + window.pageYOffset - 64;
                  window.scrollTo({ top: y, behavior: 'smooth' });
                }
              }}
            >
              Learn More
            </Button>
            <Button
              variant="outlined"
              onClick={onTrial}
              sx={{ width: { xs: "75%", md: "150px" } }}
            >
              Free Trial
            </Button>
            <BuyButton color="primary" variant="contained" product={product} sx={{ width: { xs: "75%", md: "150px" } }} />
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};

export default MainBanner;
