import { Box, Card, CircularProgress, CardContent, Dialog, Typography, Button } from "@mui/material"
import { StyledTextfield } from "../StyledTextField"
import { LoggedIn, LoggedOut } from "../AccessControl";
import { useState } from "react";
import { claimLicense } from "../../util/api/licenseApi";
import { setSuccess, setError } from "../../actions/notificationActions";
import { useDispatch } from "react-redux";
import AccountDialog from "../AccountDialog";

const isUUID = (str) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
}

const LicenseClaimDialog = ({ open, onCancel, onLicenseAdded }) => {

  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setLoading(true)
    claimLicense(key).then((resp) => {
      dispatch(setSuccess(resp.data))
      setKey("")
      setLoading(false)
      onLicenseAdded();
    }).catch((e) => {
      setLoading(false)
      dispatch(setError(e.response.data.detail))
    })
  }

  const handleCancel = () => {
    setKey("")
    onCancel();
  }

  return <Dialog
    open={open}
    onClose={handleCancel}
    fullWidth
    maxWidth="sm"
  >
    <AccountDialog
      text={"A WaveMind account is required to register your license"}
      open={accountDialogOpen}
      onClose={() => { setAccountDialogOpen(false) }} />
    <Card sx={{
      borderRadius: { xs: 0, md: 4 },
      padding: { xs: 1, md: 4 },
      pt: { xs: 0, md: 4, },
      pb: { xs: 0, md: 4, },
      background: "#1a1a1a",
      justifyContent: "center",
      alignItems: 'center',
      display: "flex"
    }}>

      <CardContent sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
        <Box sx={{ width: "100%" }} display="flex" alignItems="center" justifyContent={"center"} flexDirection={"column"}>
          <Typography>Enter the License you want to claim.</Typography>
          <StyledTextfield
            id="field1"
            label="License Key"
            variant="outlined"
            required
            size="small"
            autoComplete="off"
            placeholder="Please enter your license key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            sx={{
              mt: 3
            }}
          />

        </Box>
        <LoggedIn>
          <Button
            sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isUUID(key)}
          >
            {loading ? <CircularProgress size="23px" sx={{ color: "#000" }} /> : "Claim"}
          </Button>
        </LoggedIn>
        <LoggedOut>
          <Button
            sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
            color="primary"
            variant="contained"
            onClick={() => { setAccountDialogOpen(true) }}
            disabled={!isUUID(key)}
          >
            {loading ? <CircularProgress size="23px" sx={{ color: "#000" }} /> : "Claim"}
          </Button>
        </LoggedOut>
      </CardContent>
    </Card>
  </Dialog>

}

export default LicenseClaimDialog;