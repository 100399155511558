import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./carousel.scss"
import deadhorsePic from "../../../static/images/protone/Deadhorse.webp"
import monsterPic from "../../../static/images/protone/Monster.webp"
import midboostPic from "../../../static/images/protone/Cor.webp"

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import deadhorseBG from "../../../static/images/protone/backdrop_deadhorse.jpg"
import monsterBG from "../../../static/images/protone/monster_bg.webp"
import midboostBG from "../../../static/images/protone/midboost_bg.webp"

const DeadhorseImage = () => {
  return <img fetchpriority="high" alt="Dead Horse Overdrive" src={deadhorsePic} width="100%" style={{ maxWidth: "300px" }}></img>
}

const MonsterImage = () => {
  return <img fetchpriority="high" alt="Dead Horse Overdrive" src={monsterPic} width="100%" style={{ maxWidth: "300px" }}></img>
}

const MidImage = () => {
  return <img fetchpriority="high" alt="Dead Horse Overdrive" src={midboostPic} width="100%" style={{ maxWidth: "300px" }}></img>
}


const DeadhorseTile = () => {
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < window.innerHeight
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        p: 0,
        m: 0,
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: { xs: "block", md: "flex" },
        flexDirection: "column",
        flexGrow: 10,
        height: "100%",
        p: 2

      }}
    >
      <img
        src={deadhorseBG}
        style={{

          width: "100%",
          height: "100%",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -1,
          objectFit: "cover",
          backgroundColor: "black",
          pointerEvents: "none"
        }} preload="auto" />

      <DeadhorseImage />
      <Typography
        variant="h3"
        fontSize={30}
        sx={{ fontSize: 30, }}
      >
        Dead Horse Overdrive
      </Typography>
      <Typography
        // fontFamily={"pirulen"}
        fontSize={10}
        sx={{ fontSize: 10, }}
        style={{
          fontSize: state.mobileView ? "15px" : "20px",
          // fontWeight: 500,
        }}
      >
        Precision and Power Reimagined.
      </Typography>
      <Button
        sx={{
          mt: 2,
          background: "white",
          color: "black", width: { xs: "75%", md: "150px" }
        }}
        variant="outlined"
        onClick={() => {
          window.location.href = "/product/protone-deadhorse"
        }}
      >
        Learn More
      </Button>
    </Box>
  );
}


const MonsterTile = () => {
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < window.innerHeight
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        p: 0,
        m: 0,
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: { xs: "block", md: "flex" },
        flexDirection: "column",
        flexGrow: 10,
        height: "100%",
        p: 2

      }}
    >
      <img
        src={monsterBG}
        style={{

          width: "100%",
          height: "100%",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -1,
          objectFit: "cover",
          backgroundColor: "black",
          pointerEvents: "none"
        }} preload="auto" />

      <MonsterImage />
      <Typography
        variant="h3"
        fontSize={30}
        sx={{ fontSize: 30, }}
      >
        Monster Fuzz
      </Typography>
      <Typography
        // fontFamily={"pirulen"}
        fontSize={10}
        sx={{ fontSize: 10, }}
        style={{
          fontSize: state.mobileView ? "15px" : "20px",
          // fontWeight: 500,
        }}
      >
        Unleash the Beast.
      </Typography>
      <Button
        sx={{
          mt: 2,
          background: "white",
          color: "black", width: { xs: "75%", md: "150px" }
        }}
        variant="outlined"
        onClick={() => {
          window.location.href = "/product/protone-monsterfuzz"
        }}
      >
        Learn More
      </Button>
    </Box>
  );
}

const MidboostTile = () => {
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < window.innerHeight
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        p: 0,
        m: 0,
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: { xs: "block", md: "flex" },
        flexDirection: "column",
        flexGrow: 10,
        height: "100%",
        p: 2

      }}
    >
      <img
        src={midboostBG}
        style={{

          width: "100%",
          height: "100%",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -1,
          objectFit: "cover",
          backgroundColor: "black",
          pointerEvents: "none"
        }} preload="auto" />

      <MidImage />
      <Typography
        variant="h3"
        fontSize={30}
        sx={{ fontSize: 30, }}
      >
        Cor Mid Boost
      </Typography>
      <Typography
        // fontFamily={"pirulen"}
        fontSize={10}
        sx={{ fontSize: 10, }}
        style={{
          fontSize: state.mobileView ? "15px" : "20px",
          // fontWeight: 500,
        }}
      >
        Cut through the Mix.
      </Typography>
      <Button
        sx={{
          mt: 2,
          background: "white",
          color: "black", width: { xs: "75%", md: "150px" }
        }}
        variant="outlined"
        onClick={() => {
          window.location.href = "/product/protone-midboost"
        }}
      >
        Learn More
      </Button>
    </Box>
  );
}


const ProToneFrontpageTile = () => {
  const responsive = {

    mobile: {
      breakpoint: { max: 10000, min: 0 },
      items: 1
    }
  };

  return (



    <Carousel
      responsive={responsive}
      autoPlay
      autoPlaySpeed={3000}
      infinite
      itemClass="carousel_item_class"
      containerClass="carousel_container"
      sliderClass="sliderClass"

    >
      <MonsterTile />
      <DeadhorseTile />
      <MidboostTile />
    </Carousel>


  )
}


export default ProToneFrontpageTile;