import "./ampBannerCss.scss"
import { Grid, Typography, Box, Card, CardContent } from "@mui/material";

import ampImageGreen from "../../../static/images/cn_amp/cn_amp_pic.webp"
import ampImageRed from "../../../static/images/cn_amp/cn_amp_pic_red.webp"
import bassAmpImage from "../../../static/images/cn_amp/cn_bass_amp_pic.webp"
import ampBG from "../../../static/images/cn_amp/guitar_amp_bg.webp"
import bassBG from "../../../static/images/cn_amp/cn_bass_amp_bg.webp"

import boostVid from "../../../static/images/cn_amp/boost_vid.mp4"

import { useEffect, useState } from "react";

const TEXT_COLOR = "#fff";

const Paragraph = ({ title, children, smallTitle }) => {
  return <Box sx={{ mt: 2, textAlign: "left" }}>

    <Typography sx={{ fontSize: { md: "23px", xs: "16px" } }} variant="body2" ><b>{title}:</b> {children}</Typography>
  </Box>
}

const AmplifierBanner = () => {

  const [showGreen, setShowGreen] = useState(true);

  return <Box
    sx={{
      backgroundSize: "cover",

      p: { xs: 0, md: 10 },
      pt: 0,
      m: 0,
      flex: 100,
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
      // maxHeight: "calc(100vh)",
      background: "#1a1a1a",
      zIndex: -3,

    }}
  >
    <Box sx={{
      // display: "flex",
      position: "relative",
      height: "100%",
      width: "100%",
      borderRadius: { xs: 0, md: "30px" },
      p: { xs: 2, md: 10 },
    }}>
      <img
        loading="lazy"
        style={{

          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -2,
          objectFit: "cover",
          backgroundColor: "#1a1a1a",
          pointerEvents: "none",
          borderRadius: "30px"
        }}
        src={ampBG}
      />
      <Box
        sx={{
          display: "block",
          zIndex: -1,
          position: "absolute",
          pointerEvents: "none",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: { xs: 0, md: "24px" },
          backgroundImage: "radial-gradient(rgba(0, 0, 0, 0.2) 0%, #000 80%)",
        }}
      ></Box>
      <Grid container
        justifyContent="center"
      >
        <Grid item id="amplifier" xs={12} md={12} justifyContent={"center"} alignItems={"center"} sx={{ pl: { xs: 4, md: 10 }, pr: { xs: 4, md: 10 } }} >
          <Typography variant="h1" sx={{ mb: 0 }}>
            The Amplifier
          </Typography>

          <img src={showGreen ? ampImageGreen : ampImageRed} alt="Ecocide amplifier" loading="lazy" style={{ width: "100%" }} />
        </Grid >

        <Grid item xs={12} md={8} sx={{ pl: { xs: 4, md: 0 }, pr: { xs: 4, md: 0 }, pb: { xs: 4, md: 0 }, mt: { xs: 0, md: -10 } }}>
          <Typography sx={{ textAlign: { xs: "left", md: "center" }, fontSize: { md: "23px", xs: "16px" } }} variant="body2">
            The beating heart of <b>ToneSuite - Ecocide</b>: the guitar amp. <br /> <br />Based on one of Germany's finest amplifiers, this amp was modified to meet the requirements of Ecocide. With an overhauled preamp and a custom power section, this amplifier delivers a truly unique sound, ready to blow your mind right out of the box.
          </Typography>
        </Grid>
      </Grid>

    </Box>

    <Box>
      <Grid container sx={{ mt: { xs: 2, md: 10 } }}>
        <Grid item id="ampBoost" xs={12} md={6} justifyContent={"center"} alignItems={"center"} sx={{ flexDirection: "column", display: "flex", pl: { xs: 4, md: 10 }, pr: { xs: 4, md: 10 } }} >
          <Typography variant="h3" sx={{ mb: { xs: 2, md: 5 } }}>
            Not heavy enough?
          </Typography>

          <Typography sx={{ textAlign: { xs: "left", md: "center" }, fontSize: { md: "23px", xs: "16px" } }} variant="body2">
            Enable the boost to enter the redzone. This mode pushes the amplifier over the edge, giving you all the gain you'll ever need.
          </Typography>
        </Grid >

        <Grid item id="overview" xs={12} md={6} justifyContent={"center"} alignItems={"center"} sx={{ mt: { xs: 2, md: 0 }, pr: { xs: 0, md: 10 } }} >
          <video
            muted defaultMuted autoPlay playsInline loop oncontextmenu="return false;" style={{ width: "100%", borderRadius: "10px" }}>
            <source src={boostVid} type="video/mp4"></source>
          </video>
        </Grid >

      </Grid>

    </Box>

    <Box sx={{
      // display: "flex",
      position: "relative",
      height: "100%",
      width: "100%",
      borderRadius: "30px",
      p: { xs: 0, md: 10 },
      mt: { xs: 0, md: 10 }
    }}>
      <img
        loading="lazy"
        style={{

          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -2,
          objectFit: "cover",
          backgroundColor: "#1a1a1a",
          pointerEvents: "none",
          borderRadius: "30px"
        }}
        src={bassBG}
      />
      <Box
        sx={{
          display: "block",
          zIndex: -1,
          position: "absolute",
          pointerEvents: "none",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: { xs: 0, md: "24px" },
          backgroundImage: "radial-gradient(rgba(0, 0, 0, 0.2) 0%, #000 80%)",
        }}
      ></Box>
      <Grid container
        justifyContent="center"
      >
        <Grid item id="amplifier" xs={12} md={12} justifyContent={"center"} alignItems={"center"} sx={{ pl: { xs: 4, md: 10 }, pr: { xs: 4, md: 10 } }} >
          <Typography variant="h1" sx={{ mb: 0, mt: { xs: 3, md: 0 } }}>
            Bring the Bass
          </Typography>

          <img loading="lazy" className="crossFade" src={bassAmpImage} alt="Ecocide Bass" style={{ width: "100%" }} />
        </Grid >

        <Grid item xs={12} md={8} sx={{ pb: { xs: 4, md: 0 }, pl: { xs: 4, md: 0 }, pr: { xs: 4, md: 0 } }}>
          <Typography sx={{ textAlign: { xs: "left", md: "center" }, fontSize: { md: "23px", xs: "16px" } }} variant="body2">
            Essential for a good mix: a powerful, yet tight bass sound. <br /> <br />This custom multiband bass processor gives you all tools you need to achieve this. A low band compressor followed by two bands of overdrive gives you all the flexibility to design your bass tone. The 5 band equalizer allows you to fine tune the sound.
          </Typography>
        </Grid>
      </Grid>

    </Box>

  </Box >
}

export default AmplifierBanner;
