import dhImage from "../../../../static/images/protone/monster_image.webp"
import { Box, Grid, Typography } from "@mui/material";

const OverviewBanner = () => {
  return <Grid container justifyContent={"center"} alignItems={"center"} sx={{ pt: 10, pb: 10, bgcolor: "white" }}>
    <Grid item xs={12} md={4}>
      <Box sx={{ display: "flex", p: 2 }}>


        <img
          src={dhImage}
          style={{
            width: "100%"
          }}
        />
      </Box>
    </Grid>

    <Grid item xs={12} md={4}>
      <Box sx={{ display: "flex", color: "black", flexDirection: "column", p: 2 }}>

        <Typography variant="h3" sx={{ color: "black" }}>
          Overview
        </Typography>

        <Typography>
          <p>
            Prepare to awaken a kaiju of sonic destruction with the Monster Fuzz, a colossal digital juggernaut that takes the raw fury of its analog predecessor and mutates it into a feature-packed titan. Twist the Fuzz knob, and you'll hear the beast roar—from a low, menacing growl to an apocalyptic bellow that flattens everything in its path.
          </p>

          <p>
            The Filter control is its fiery breath, spewing out an insane range of bandpass-filtered tones with seismic girth and sizzling overtones. Crank the Volume, and it'll thunder through your rig with enough power to make even the most stoic stacks tremble.
          </p>

          <p>
            Harness the Scoop control to carve out classic mid-scoop destruction or flatten the mid frequencies for a wave of unstoppable carnage. The Crunch knob reins in or releases the beast's crushing compression—choose between a restrained stomp or an unbridled rampage.
          </p>

          <p>
            Voice dials up the intensity of the mid-scoop, shaping the beast's roar from a guttural growl to an all-out howl, while Clip lets you switch between smooth, controlled fury or wild, chaotic fuzz sustain. The Monster Fuzz isn't just a pedal—it's a towering titan of tone, ready to crush the competition underfoot.</p>

          We present: <b>Pro Tone Pedals - Monster Fuzz</b>

        </Typography>

      </Box>
    </Grid>

  </Grid>
}

export default OverviewBanner;