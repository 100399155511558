import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../../actions/tokenActions';

export const LoggedOut = ({ children }) => {
  const state = useSelector((state) => state)

  const hasToken = () => state.token !== null && state.token !== undefined && state.token.raw !== undefined;

  var dateNow = new Date();
  let expired = state?.token?.data?.exp < (dateNow.getTime() / 1000)

  if (!hasToken() || expired) {
    return children
  }

  return ""
}

export const LoggedIn = ({ children }) => {

  const state = useSelector((state) => state)
  const dispatch = useDispatch();

  const hasToken = () => state.token !== null && state.token !== undefined && state.token.raw !== undefined;

  var dateNow = new Date();
  let expired = state?.token?.data?.exp < (dateNow.getTime() / 1000)

  if (expired === true) {
    dispatch(setToken(null));
  }

  if (hasToken() && !expired) {
    return children
  }

  return ""
}