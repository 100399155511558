import dhImage from "../../../../static/images/protone/deadhorse_overview.webp"
import { Box, Grid, Typography } from "@mui/material";

const OverviewBanner = () => {
  return <Grid container justifyContent={"center"} sx={{ pt: 10, pb: 10, bgcolor: "white" }}>
    <Grid item xs={12} md={4}>
      <Box sx={{ display: "flex", p: 2 }}>


        <img
          src={dhImage}
          style={{
            width: "100%"
          }}
        />
      </Box>
    </Grid>

    <Grid item xs={12} md={4}>
      <Box sx={{ display: "flex", color: "black", flexDirection: "column", p: 2 }}>

        <Typography variant="h3" sx={{ color: "black" }}>
          Overview
        </Typography>

        <Typography>
          <p>
            Meet the Dead Horse Overdrive Digital Edition, the evolution of Pro Tone Pedals' flagship powerhouse. Building on the legacy of the analog MKIII model, this digital reimagining combines the raw, unmistakable tone of the original with enhanced flexibility and precision for today's guitarist.
          </p>

          <p>
            Since it first hit pedalboards in 2007, the Dead Horse Overdrive has been synonymous with tonal excellence, giving players the tools to craft everything from tight, crunchy riffs to soaring leads. The digital version raises the bar, offering classic Dead Horse response, the iconic Tube Screamer mid hump, and bass-friendly curves perfect for modern extended-range guitars.
          </p>

          <p>
            Leading the upgrades is the Bass Frequency Selector, now optimized for unparalleled tonal sculpting. With multiple settings, you can shape the pedal's 'voice' to suit any playing style, whether you're delivering tight, articulate rhythms or unleashing commanding leads.
          </p>

          <p>
            The Dead Horse Overdrive Digital Edition brings the timeless punch of the analog original into a versatile, future-ready format. Refined, powerful, and ready to perform—this is tone without limits.
          </p>

          We present: <b>Pro Tone Pedals - Dead Horse Overdrive</b>

        </Typography>

      </Box>
    </Grid>

  </Grid>
}

export default OverviewBanner;