import axios from "axios";

export const postContact = async (name: string, email: string, content: string) => {

    const data = {
        name: name,
        email: email,
        content: content,
    }

    return await axios.post(
        `/api/support/`,
        data
    )
}