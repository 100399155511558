import { Grid, Typography, Link } from "@mui/material";

import TopBar from "../../components/TopBar";
import BottomBar from "../../components/BottomBar";

import backgroundImage from "../../static/images/background_desktop.webp";



const StripeEmailValidation = () => {
  return (
    <div style={{ minHeight: "100vh", minWidth: "100vw", display: "flex", flexDirection: "column", background: `url(${backgroundImage})` }}>
      <TopBar />
      <Grid container justifyContent={"center"} sx={{ padding: { md: 10, xs: 1 }, flex: 500, }}>

        <Grid justifyContent="center" alignItems="center" sx={{ flexDirection: "column", display: "flex", gap: 2 }} lg={8} md={10} xs={12} >
          <Typography variant="h3">Info@wavemind.nl</Typography>
          <Typography variant="h3">Chris@wavemind.nl</Typography>
          <Typography variant="h4"><Link href="/">Return Home</Link></Typography>
        </Grid>
      </Grid>
      <BottomBar />
    </div >
  );
};

export default StripeEmailValidation;