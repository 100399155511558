import { Grid, Typography, Box } from "@mui/material";

import cabImage from "../../../static/images/revf/cabinet.webp"
import cabInterface from "../../../static/images/revf/rev_f_cab_view.webp"

const CabinetBanner = () => {
  const textColor = "#000"
  return <Grid id="cabBanner" container sx={{ width: "100%", pt: 0, pb: 0, background: "radial-gradient(circle, rgb(204, 204, 214) 0%, rgb(86, 86, 105) 100%)" }} justifyContent={"center"} >

    <Grid item xs={12} md={12} justifyContent={"center"} alignItems={"center"}>
      <Grid container sx={{ width: "100%" }} alignItems={"center"} justifyContent={"center"}>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, pb: "0 !important", color: textColor, }}>
          <Typography variant="h2" sx={{ fontWeight: "bold", textAlign: "center" }}>Cabinet Simulation</Typography>
        </Grid>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ pl: { xs: 0, md: 10, }, pr: { xs: 0, md: 10, }, color: textColor, }}>
          <Box sx={{ width: { xs: "100vw", md: "100%" } }}>
            <img loading="lazy" fetchpriority="low" alt="Amp Suite Rev F Cabinet" width="100%" src={cabImage} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, paddingTop: "0 !important", color: textColor, }}>

          <Typography fontFamily={"montserrat"} sx={{ mt: 2, fontSize: { md: "30px", xs: "16px", textAlign: "center" } }} >
            <p>An amp is nothing without a good cabinet, that's why we included a fully modelled cab section based on an oversized 4x12.</p>
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, pb: "0 !important", paddingTop: "0 !important", color: textColor, }}>
          <img loading="lazy" fetchpriority="low" alt="Amp Suite Rev F Cabinet Interface" width="100%" src={cabInterface} />
        </Grid>

        <Grid item xs={12} md={8} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, paddingTop: "0 !important", color: textColor, }}>

          <Typography fontFamily={"montserrat"} sx={{ mt: 2, fontSize: { md: "30px", xs: "16px", textAlign: "center" } }} >
            <p>Our speaker simulator allows you to precisely position your virtual microphone, even after you're done recording. You can even mix this together with your own custom impulses.</p>
          </Typography>
        </Grid>


      </Grid>
    </Grid >
  </Grid>
}

export default CabinetBanner;
