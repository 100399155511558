import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import primeLight from '../static/fonts/Prime-Light.woff2';
import primeRegular from '../static/fonts/Prime-Regular.woff2';

import montserrat from "../static/fonts/Montserrat-VariableFont_wght.ttf"
import eurostyle from "../static/fonts/eurostyle_normal.ttf";

const prime = {
  fontFamily: 'Prime',
  fontStyle: 'normal',
  fontDisplay: undefined,
  fontWeight: 400,
  src: `
    local('Prime-Regular'),
    url(${primeRegular}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const primeL = {
  fontFamily: 'Prime',
  fontStyle: 'normal',
  fontDisplay: undefined,
  fontWeight: 100,
  src: `
    local('Prime-Light'),
    url(${primeLight}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const Montserrat = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: undefined,
  fontWeight: 400,
  src: `
    local('Montserrat'),
    url(${montserrat}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const Eurostyle = {
  fontFamily: 'Eurostyle',
  fontStyle: 'normal',
  fontDisplay: undefined,
  fontWeight: 400,
  src: `
    local('Eurostyle'),
    url(${eurostyle}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};


let Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1650,
      xl: 1800,
    },
  },
  palette: {
    primary: {
      dark: 'rgb(249,245,238)',
      main: 'rgb(249,245,238)',
      light: 'rgb(249,245,238)',
    },
    secondary: {
      dark: 'rgb(249, 245, 238)',
      main: 'rgb(249,245,238)',
      light: 'rgb(249,245,238)',
    },
    background: {
      default: 'rgb(0, 0, 0)',
      paper: 'rgb(0, 0, 0)',

    },
    action: {
      disabledBackground: '#ffffffee',
      disabled: '#00000080'
    },
    text: {
      primary: 'rgb(249,245,238)',
      secondary: 'rgb(249,245,238)',
      disabled: 'rgb(249,245,238)',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Eurostyle'
    },
    h3: {
      fontFamily: 'montserrat'
    },
    h2: {
      fontFamily: 'montserrat'
    },
    h4: {
      fontFamily: 'montserrat'
    },
    h5: {
      fontFamily: 'montserrat'
    },
    fontFamily: 'montserrat',

  },
  components: {
    MuiCssBaseline: {
      styleOverrides:
      {
        '@font-face': [Montserrat],
      }
    },
  },
});

Theme = responsiveFontSizes(Theme);
Theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [Theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
  fontFamily: "Montserrat",
};

Theme.typography.h2 = {
  fontSize: '6.5vw',
  '@media (min-width:800px)': {
    fontSize: '3.6vw',
  },
  fontFamily: "Montserrat",
};

Theme.typography.h1 = {
  fontSize: '6.5vw',
  '@media (min-width:800px)': {
    fontSize: '3.6vw',
  },
  fontFamily: "Montserrat",
};

Theme.typography.body2.fontSize = '4vw';
Theme.typography.body2['@media (min-width:800px)'] = { fontSize: '2vw', };

export default Theme;
