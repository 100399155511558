import axios from "axios";


export const deleteLicenseActivation = async (licenseID: string, activationID: string) => {
    return axios.delete(`/api/license/${licenseID}/activations/${activationID}`)
}

export const claimLicense = async (licenseID: string) => {
    const data = {
        key: licenseID
    }
    return axios.post(`/api/license/claimLicense`, data)
}