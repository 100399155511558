import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import TopBar from "../../components/TopBar";
import { Box, Card, CardContent, Grid, Button, Typography, CircularProgress } from "@mui/material";

import BottomBar from "../../components/BottomBar";
import { confirmEmail } from "../../util/api/emailApi";
import { setError } from "../../actions/notificationActions";


const EmailConfirmPage = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [errorActive, setErrorActive] = useState(false);

  const dispatch = useDispatch()
  let [searchParams] = useSearchParams();

  const handleConfirmEmail = () => {
    const confirmKey = searchParams.get("key");

    confirmEmail(confirmKey).then(() => {
      setSuccess(true)
      setLoading(false)
    }).catch((e) => {
      setErrorActive(true)
      setLoading(false)
      dispatch(setError(e.response?.data?.detail))
    })
  }

  useEffect(() => {
    handleConfirmEmail()
    // eslint-disable-next-line
  }, [])

  const SuccessMessage = () => {
    return (
      <>
        <Typography variant="h4">Registration Complete!</Typography>
        <Typography sx={{ mt: 5 }} variant="body1">
          Your account has been activated!
        </Typography>
      </>
    )
  }

  const ErrorMessage = () => {
    return (
      <>
        <Typography variant="h4">Something went wrong...</Typography>
        <Typography sx={{ mt: 5 }} variant="body1">
          Your email confirmation could not be completed. Did you click the correct link in your email?
        </Typography>
      </>
    )
  }

  const LoadingSpinner = () => {
    return <CircularProgress size={"100px"} />
  }

  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <TopBar />
    <Grid container justifyContent={"center"} sx={{ padding: { md: 10, xs: 1 }, flex: 500, background: "white" }}>
      <Grid item xs={12} md={6} lg={4}>

        <Card sx={{
          borderRadius: { sx: 1, md: 4 },
          padding: { xs: 1, md: 4 },
          pt: { xs: 0, md: 4, },
          pb: { xs: 0, md: 4, },
          background: "#1a1a1a",
          justifyContent: "center",
          alignItems: 'center',
          display: "flex"
        }}>
          <CardContent sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
            <Box sx={{ width: "100%" }} display="flex" alignItems="center" justifyContent={"center"} flexDirection={"column"}>
              {loading && <LoadingSpinner />}
              {errorActive && <ErrorMessage />}
              {success && <SuccessMessage />}

              <Button
                sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                variant="contained" color="primary"
                href="/login"
              >
                Go to Login
              </Button>

              <Box sx={{ mt: 3, width: "100%" }}>
                <Box sx={{ textAlign: "left", width: "100%", display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ fontSize: 15, ml: 0, mr: "auto" }}>
                    <a style={{ textAlign: "left", textDecoration: "underline" }} href="/forgotPassword">Forgot password?</a>
                  </Typography>

                  <Typography sx={{ fontSize: 15, mr: 0, ml: "auto" }}>
                    <a style={{ textAlign: "right", textDecoration: "underline" }} href="/register">Create Account</a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
    <BottomBar />
  </div >
}

export default EmailConfirmPage;