import { Box, Grid, Typography } from "@mui/material";
import BottomBar from "../../components/BottomBar"
import TopBar from "../../components/TopBar"
import { Helmet } from "react-helmet";

const TermsContent = <p>By accessing the website at <a href="/">wavemind.net</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>

const UseLicenseContent = <p>
  Permission is granted to temporarily download one copy of the materials (information or software) on WaveMind’s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
  <ul>
    <li>modify or copy the materials;</li>
    <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
    <li>attempt to decompile or reverse engineer any software contained on WaveMind’s website;</li>
    <li>remove any copyright or other proprietary notations from the materials; or</li>
    <li>transfer the materials to another person or “mirror” the materials on any other server.</li>
  </ul>

  This license shall automatically terminate if you violate any of these restrictions and may be terminated by WaveMind at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
</p>

const DisclaimerContent = <p>
  The materials on WaveMind’s website are provided on an ‘as is’ basis. WaveMind makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
  Further, WaveMind does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
</p>

const AccuracyOfMaterialsContent = <p>
  The materials appearing on WaveMind’s website could include technical, typographical, or photographic errors. WaveMind does not warrant that any of the materials on its website are accurate, complete or current. WaveMind may make changes to the materials contained on its website at any time without notice. However, WaveMind does not make any commitment to update the materials.
</p>

const LinksContent = <p>
  WaveMind has not reviewed all the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by WaveMind of the site. Use of any such linked website is at the user’s own risk.
</p>

const ModificationsContent = <p>
  WaveMind may revise these terms of service for its website at any time without notice. By using this website, you are agreeing to be bound by the then current version of these terms of service.
</p>

const Paragraph = ({ title, content }) => {
  return <Box sx={{ mt: 2 }}>
    <Typography variant="h6"><b>{title}</b></Typography>
    <Typography sx={{ pl: 2 }} variant="body1">{content}</Typography>
  </Box>
}

const TermsOfService = () => {
  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <Helmet>
      <title>WaveMind Audio | Terms of Service</title>
      <link rel="canonical" href="https://wavemind.net/terms" />
    </Helmet>
    <TopBar />
    <Grid sx={{ justifyContent: "center", flex: 500, padding: 4, mb: 4 }} container>
      <Grid item xs={12} md={8} lg={6}>
        <Typography variant="h2">Terms of Service</Typography>
        <Paragraph title="1. Terms" content={TermsContent} />
        <Paragraph title="2. Use License" content={UseLicenseContent} />
        <Paragraph title="3. Disclaimer" content={DisclaimerContent} />
        <Paragraph title="4. Accuracy of Materials" content={AccuracyOfMaterialsContent} />
        <Paragraph title="5. Links" content={LinksContent} />
        <Paragraph title="6. Modifications" content={ModificationsContent} />
      </Grid>
    </Grid>
    <BottomBar />
  </div>
}

export default TermsOfService;