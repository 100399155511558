import { useState } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { StyledTextfield } from "./StyledTextField";
import { useDispatch } from "react-redux";
import { subscribeEmail } from "../util/api/emailApi";
import { setError, setSuccess } from "../actions/notificationActions";

const EmailField = ({ sx, labelText = "Subscribe to our newsletter", buttonText = "Subscribe", onSubmit = () => { } }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setEmailError(email === "")
    if (email === "")
      return

    subscribeEmail(email).then(() => {
      setEmailError(false)
      setEmail("")
      // dispatch(setSuccess("Thank you for subscribing to our newsletter!"))

      onSubmit()
    }).catch(() => {
      dispatch(setError("Could not subscribe to newsletter."))
    })
  }
  return <Box
    sx={{ width: { xs: "300px", md: "500px" }, justifyContent: "center", display: "flex", alignItems: "center", mb: 1, ...sx }}
  >
    <StyledTextfield
      label={labelText}
      type="email"
      placeholder="Enter your email"
      variant="outlined"
      value={email}
      error={emailError}
      required
      size="small"
      onChange={(e) => { setEmail(e.target.value) }}
      InputProps={{
        sx: {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }
      }}
    />
    <Button onClick={handleSubmit} sx={{ padding: "8px 14px", height: "40px", width: "40%", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} variant="contained">{buttonText}</Button>

  </Box >
}

export default EmailField;