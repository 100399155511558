import { SET_TOKEN, SET_LOADING } from '../types/actions';
import { logIn as loginAPI, fetchToken } from '../util/api/userApi';
import store from '../Store';
import { setError } from './notificationActions';

export const setToken = (token: any) => ({
  type: SET_TOKEN,
  payload: token
})

export const setLoading = (loading: any) => ({
  type: SET_LOADING,
  payload: loading
})

export const logIn = (username: string, password: string, redirectUrl: string = "/account") => (dispatch: any) => {
  loginAPI(username, password).then((res) => {
    const token = res.data;
    dispatch(setToken(token))
    window.location.href = redirectUrl;
  })
    .catch((e) => {
      dispatch(setError(e.response?.data?.detail))
      console.log(e);
      console.error("Error in login");
    });
}

export const logOut = () => (dispatch: any) => {
  store().persistor.purge();
  window.location.href = "/"
  dispatch(setToken(null));
}

export const logOutAndLogin = () => (dispatch: any) => {
  store().persistor.purge();
  window.location.href = "/login?redirect=" + window.location.pathname
  dispatch(setToken(null));
}


export const retrieveToken = () => (dispatch: any) => {
  dispatch(setLoading(true));
  fetchToken()
    .then((res: any) => dispatch(setToken(res.data)))
    .then(() => dispatch(setLoading(false)))
    .catch((e: any) => {
      console.log('error while fetching token', e)
      dispatch(setLoading(false))
    })
}
