import { Grid, Box, Typography, Button } from "@mui/material";

import logo from "../static/images/Logo.png";
import EmailField from "./EmailField";

const EmailHarvesting = () => {


  return (
    <Grid container spacing={0} justifyContent="center" sx={{ pb: 4, fontSize: "13px", background: "#1a1a1a" }}>
      <Grid xs={12} md={8} sx={{ p: { xs: 4, md: 8 }, pb: 4, display: "flex", justifyContent: "center" }}>

        <Box sx={{ textAlign: "center", }}>

          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Typography style={{ fontSize: { xs: "16px", md: "30px" } }} variant="h6">Stay up to date</Typography>
          </Box>
          <EmailField />

          <Typography variant="caption">By subscribing you agree to our <a style={{ textDecoration: "underline" }} href="/privacy">privacy policy</a></Typography>
          {/* <Box sx={{ mt: 2 }}>
            <img src={logo} alt="WaveMind" height={"20px"} style={{ borderRadius: 0 }} />
          </Box> */}
        </Box>

      </Grid>
    </Grid>
  )
}

export default EmailHarvesting;