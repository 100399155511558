import dhImage from "../../../../static/images/protone/midboost_image.webp"
import { Box, Grid, Typography } from "@mui/material";

const OverviewBanner = () => {
  return <Grid container justifyContent={"center"} alignItems={"center"} sx={{ pt: 10, pb: 10, bgcolor: "white" }}>
    <Grid item xs={12} md={4}>
      <Box sx={{ display: "flex", p: 2 }}>


        <img
          src={dhImage}
          style={{
            width: "100%"
          }}
        />
      </Box>
    </Grid>

    <Grid item xs={12} md={4}>
      <Box sx={{ display: "flex", color: "black", flexDirection: "column", p: 2 }}>

        <Typography variant="h3" sx={{ color: "black" }}>
          Overview
        </Typography>

        <Typography>
          <p>
            The Cor Mid Boost Digital Edition is here to redefine your tone. With precise midrange control and a new Voice feature to dial in the perfect level of aggression, this isn't just another EQ pedal—it's your key to cutting through any mix with razor-sharp precision or smooth, balanced clarity.
          </p>

          <p>
            Got a sound buried in the mud? Not anymore. Sculpt your mids with surgical precision, from low-end warmth to hi-mid razor slices. The Voice control lets you decide just how mean or subtle you want to sound, putting total tonal precision right at your fingertips.
          </p>

          <p>
            Whether you're adding some bite to a scooped distortion, taming a mid-heavy amp, or launching your almost-breaking-up rig into roaring glory, the Cor Mid Boost adapts to your every whim. It doesn't just play well with others—it makes them sound better. Overdrive, distortion, clean? No problem. Whatever your rig, the Cor Mid Boost gives you the authority to cut through the densest walls of sound.
          </p>

          <p>
            This is tone control without limits—no gimmicks, no compromises. The Cor Mid Boost is here to take your sound from good to godlike. Are you ready to command the mix?
          </p>

          We present: <b>Pro Tone Pedals - Cor Mid Boost</b>

        </Typography>

      </Box>
    </Grid>

  </Grid>
}

export default OverviewBanner;