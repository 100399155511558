import jwt_decode from "jwt-decode";
import { SET_TOKEN, SET_LOADING } from "../types/actions"

const tokenReducer = (state: any = { raw: undefined, data: {} }, action: any) => {
  switch (action.type) {
    case SET_TOKEN:
      if (action.payload !== null) {
        const accessToken = jwt_decode(action.payload.access_token)
        return { raw: action.payload, data: accessToken };
      } else {
        return null
      }

    case SET_LOADING:
      return { ...state };
    default:
      return state;
  }
}

export default tokenReducer;
