import { useDispatch } from 'react-redux';
import { useSearchParams } from "react-router-dom";

import TopBar from "../../components/TopBar";
import { Box, Card, CardContent, Grid, Button, Typography } from "@mui/material";

import BottomBar from "../../components/BottomBar";
import { resendConfirmationEmail } from "../../util/api/emailApi";
import { setError, setSuccess } from "../../actions/notificationActions";


const RegisterSuccessPage = () => {
  const dispatch = useDispatch()
  let [searchParams] = useSearchParams();


  const handleResend = () => {
    const userID = searchParams.get("user");
    if (!userID)
      dispatch(setError("No user found"))

    resendConfirmationEmail(userID).then((res) => {
      dispatch(setSuccess("Email sent successfully"))
    })
  }


  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <TopBar />

    <Grid container justifyContent={"center"} sx={{ padding: { md: 10, xs: 1 }, flex: 500, background: "#fff" }}>
      <Grid item xs={12} md={6} lg={4}>

        <Card sx={{
          borderRadius: { sx: 1, md: 4 },
          padding: { xs: 1, md: 4 },
          pt: { xs: 0, md: 4, },
          pb: { xs: 0, md: 4, },
          background: "#1a1a1a",
          justifyContent: "center",
          alignItems: 'center',
          display: "flex"
        }}>
          <CardContent sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
            <Box sx={{ width: "100%" }} display="flex" alignItems="center" justifyContent={"center"} flexDirection={"column"}>
              <Typography variant="h4">Almost there!</Typography>
              <Typography sx={{ mt: 5 }} variant="body1">
                Before you can start using your WaveMind account, you need to confirm your email. This way you will be ensured to receive
                important information regarding your account and purchases.
              </Typography>

              <Typography sx={{ mt: 5 }} variant="body1">
                Haven't received anything yet? Check your spam folder or resend the verification email.
              </Typography>

              <Button
                sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                variant="contained" color="primary"
                onClick={handleResend}
              >
                Resend Confirmation
              </Button>


              <Button
                sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                variant="contained" color="primary"
                href="/login"
              >
                Go to Login
              </Button>

              <Box sx={{ mt: 3, width: "100%" }}>
                <Box sx={{ textAlign: "left", width: "100%", display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ fontSize: 15, ml: 0, mr: "auto" }}>
                    <a style={{ textAlign: "left", textDecoration: "underline" }} href="/forgotPassword">Forgot password?</a>
                  </Typography>

                  <Typography sx={{ fontSize: 15, mr: 0, ml: "auto" }}>
                    <a style={{ textAlign: "right", textDecoration: "underline" }} href="register">Create Account</a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>


    </Grid >
    <BottomBar />
  </div >
}

export default RegisterSuccessPage;