import { Grid, Typography } from "@mui/material";

import eqImage from "../../../static/images/revf/eq.webp"

const EQBanner = () => {
  return <Grid container sx={{ width: "100%", pt: 0, pb: 0, background: "radial-gradient(circle, #ff5c5c 0%, rgb(170, 27, 31) 100%)" }} justifyContent={"center"}>
    <Grid item xs={12} md={12} justifyContent={"center"} alignItems={"center"}>
      <Grid container sx={{ width: "100%" }} alignItems={"center"} justifyContent="center">
        <Grid item xs={12} md={6} alignItems={"center"}>
          <img loading="lazy" fetchpriority="low" alt="Amp Suite Rev F EQ" width="100%" src={eqImage} />
        </Grid>
        <Grid item xs={12} md={6} alignItems={"center"} sx={{ p: { xs: 4, md: 10 }, textAlign: "center" }}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>7-band EQ</Typography>
          <Typography fontFamily={"montserrat"} sx={{ mt: 2, fontSize: { md: "30px", xs: "16px" } }}>With the 7-band equalizer you can shape your tone exactly as you see fit.</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
}

export default EQBanner;
