import { Grid, Typography, Box } from "@mui/material";

import laptop from "../../../../static/images/protone/laptop_cor.webp"
import asioLogo from "../../../../static/images/asio_logo.jpg"

const SystemRequirements = () => {
  const textColor = "#000"
  return <Grid container justifyContent={"center"} sx={{ pt: 10, pb: 10, bgcolor: "white" }}>

    <Grid item xs={12} md={12} justifyContent={"left"} alignItems={"center"}>
      <Grid container sx={{ width: "100%" }} alignItems={"center"} justifyContent={"center"}>

        <Grid item xs={12} md={4} alignItems={"center"} sx={{ p: { xs: 0, md: 2 }, color: textColor, display: "flex" }} justifyContent={"center"}>
          <img loading="lazy" fetchpriority="low" alt="Cor Mid Boost" width="100%" src={laptop} style={{ maxWidth: "700px", display: "block" }} />
        </Grid>
        <Grid item xs={12} md={4} alignItems={"center"} sx={{ p: { xs: 4, md: 2, color: textColor }, mt: "0 !important", textAlign: "left" }}>
          <Typography variant="h3" sx={{ color: "black" }}>
            System Requirements
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, mt: 2, mb: 2 }}>
            <Box><b>Formats:</b> <i>Pro Tone - Cor Mid Boost</i> is available in 64-bit VST3, AU and AAX formats.</Box>
            <Box><b>OS:</b> Windows® 10 64 Bit (latest update) or MacOS® 10.13+</Box >
            <Box><b>Processor:</b> Minimum AVX support (Intel Haswell® or AMD Excavator® and up)</Box >
            <Box><b>Memory:</b> 8 GB</Box >
            <Box><b>Storage:</b> 2GB+ of free space</Box >
            <Box><b>Account:</b> A Free WaveMind account is required to register your license.</Box>
            <Box><b>Internet:</b> An internet connection required to activate your license through your WaveMind account.</Box>
          </Box>
          <Typography fontSize={12}><b>Note: </b>these specs are estimates and are considered the bare minimum. For a better experience, higher specs are recommended.</Typography>

        </Grid>
      </Grid>
    </Grid>
  </Grid>
}

export default SystemRequirements;
